import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import fetchData from '../fetch';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createSaleTokenChargeId, getCheckoutData } from '../redux/actions/checkoutPayment';
import { Redirect, useParams } from 'react-router-dom';
import SaleTokenWrapper from '../components/SaleTokenCheckout/SaleTokenWrapper/SaleTokenWrapper';
import SaleTokenForm from '../components/SaleTokenCheckout/SaleTokenForm';
import SaleTokenSummary from '../components/SaleTokenCheckout/SaleTokenSummary';
import NotFound from '../components/CheckoutPayment/NotFound';
import { Helmet } from 'react-helmet';
import { AppConfig } from '../config';

const SaleTokenCreator = ({
  checkoutData,
  checkoutDataFetching,
  getCheckoutData,
  chargeIdState,
  createSaleTokenChargeId,
  checkoutDataError,
}) => {
  const [isRedirect, setIsRedirect] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [estimate, setEstimate] = useState(undefined);
  const [isMinMoreThanMax, setIsMinMoreThanMax] = useState(false);
  const [estimateDirection, setEstimateDirection] = useState('direct');
  const [timer, setTimer] = useState(null);
  const [isEstimateExpired, setIsEstimateExpired] = useState(false);
  const timerRef = useRef(null);
  const { identifier } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(
    () => () => {
      timerRef.current && clearInterval(timerRef.current);
    },
    []
  );

  useEffect(() => {
    if (timerRef.current && timer <= 0) {
      clearInterval(timerRef.current);
      setTimer(null);
      timerRef.current = null;
      setIsEstimateExpired(true);
    }
  }, [timer]);

  useEffect(() => {
    if (estimate) {
      const { validUntil } = estimate;
      const timeLeft = new Date(validUntil * 1000) - new Date();
      if (timeLeft > 0) {
        setTimer(timeLeft);
        if (!timerRef.current) {
          setIsEstimateExpired(false);
          timerRef.current = setInterval(() => {
            setTimer(timer => (timer ? timer - 1000 : timeLeft));
          }, 1000);
        }
      } else {
        setIsEstimateExpired(true);
      }
    }
  }, [estimate]);

  useEffect(() => {
    if (identifier === 'undefined') return history.push('/charge-not-found');
    identifier && getCheckoutData(identifier);
  }, [identifier, history]);

  useEffect(() => {
    if (
      checkoutData &&
      (+checkoutData.min > +checkoutData.max || (+checkoutData.min === 0 && +checkoutData.max === 0))
    ) {
      setIsMinMoreThanMax(true);
    }
  }, [checkoutData]);

  useEffect(() => {
    if (chargeIdState && chargeIdState.data) setIsRedirect(true);
  }, [chargeIdState]);

  const millisToMinutesAndSeconds = millis => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  };

  const getEstimate = async (currencyFrom, amount, type) => {
    const response = await fetchData(
      `checkout-client/${identifier}/estimate?${qs.stringify({ currencyFrom, amount, type })}`
    );
    return response;
  };

  return <>
  {AppConfig.identity === 'cpay' && (
    <Helmet>
      <link rel="icon" href="/cpay-icons/favicon.ico" type="image/x-icon" />
    </Helmet>
  )}

  {AppConfig.identity === 'nfg' && (
    <Helmet>
    <link rel="icon" href="/nfgpay-icons/favicon.ico" type="image/x-icon" />
  </Helmet>
  )}

  {!isRedirect ? (
    checkoutData && (!checkoutDataError || checkoutDataError.code !== 404) ? (
      <SaleTokenWrapper
        title={checkoutData.productName}
        description={checkoutData.description}
        image={checkoutData.image}
        logoImage={checkoutData.logoImage}
        backgroundColor={checkoutData.backgroundColor}
      >
        {!showSummary ? (
          <SaleTokenForm
            identifier={identifier}
            checkoutData={checkoutData}
            chargeIdState={chargeIdState}
            createSaleTokenChargeId={createSaleTokenChargeId}
            setShowSummary={setShowSummary}
            summaryData={summaryData}
            setSummaryData={setSummaryData}
            estimate={estimate}
            setEstimate={setEstimate}
            getCheckoutData={getCheckoutData}
            getEstimate={getEstimate}
            isMinMoreThanMax={isMinMoreThanMax}
            timer={millisToMinutesAndSeconds(timer)}
            isEstimateExpired={isEstimateExpired}
            estimateDirection={estimateDirection}
            setEstimateDirection={setEstimateDirection}
          />
        ) : (
          <SaleTokenSummary
            identifier={identifier}
            summaryData={summaryData}
            setSummaryData={setSummaryData}
            setShowSummary={setShowSummary}
            checkoutData={checkoutData}
            createSaleTokenChargeId={createSaleTokenChargeId}
            estimate={estimate}
            setEstimate={setEstimate}
            getEstimate={getEstimate}
            timer={millisToMinutesAndSeconds(timer)}
            isEstimateExpired={isEstimateExpired}
            estimateDirection={estimateDirection}
          />
        )}
      </SaleTokenWrapper>
    ) : (
      !checkoutDataFetching && <NotFound title={t('checkouts.checkoutForm.charge')} />
    )
  ) : (
    <Redirect to={`/sale-token-charge/${chargeIdState.data}`} />
  )};
  </>
};

const mapStateToProps = state => ({
  checkoutData: state.checkoutPayment.getCheckoutData.data,
  checkoutDataFetching: state.checkoutPayment.getCheckoutData.fetching,
  checkoutDataError: state.checkoutPayment.getCheckoutData.error,
  chargeIdState: state.checkoutPayment.chargeId,
});

const mapDispatchToProps = {
  getCheckoutData,
  createSaleTokenChargeId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleTokenCreator);
