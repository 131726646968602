import React from 'react';
import { Link } from 'react-router-dom';
import ReleaseNotes from '../components/ReleaseNotes';
import HeaderUser from '../components/HeaderUser/HeaderUser';
import MerchantSelector from '../components/MerchantSelector/MerchantSelector';
import useWindowSize from '../utils/resizeHook';
import Hamburger from '../components/Hamburger';
import './Header.scss';
import { AppConfig } from '../config';
import logoCpay from '../img/footer/footerLogo.svg';
import logoNfg from '../img/nfgpay-svg/nfg_logo_white.svg';
import TransakOpen from '../utils/transakSDK';
import { identity } from '../utils/getIdentity';

const logos = {
  cpay: logoCpay,
  nfg: logoNfg,
};

function Header({ toggleCollapseSider, siderState }) {
  const { currentWidth, isLg } = useWindowSize();

  return (
    <div className={`header header-${identity}`}>
      <div className="header__left">
        <Hamburger handler={toggleCollapseSider} opened={siderState} />
        {isLg && (
          <Link to="/">
            <img src={logos[AppConfig.identity]} alt="logo" />
          </Link>
        )}
      </div>
      <div className="header__right">
        <button
          className={`header__transak-button header__transak-button-${identity}`}
          onClick={TransakOpen}
          type="button"
        >
          Buy/Sell Crypto
        </button>
        <div className="header__controls-wrapper header__merchant-selector-wrapper">
          {currentWidth >= 1024 ? <MerchantSelector /> : null}
        </div>
        {AppConfig.showReleaseNotes && (
          <div className="header__controls-wrapper header__release-notes">
            <ReleaseNotes />
          </div>
        )}
        <div className="header__controls-wrapper">
          <HeaderUser />
        </div>
        {currentWidth < 768 && (
          <Link to="/">
            <img src={logos[AppConfig.identity]} alt="logo" />
          </Link>
        )}
      </div>
    </div>
  );
}

export default Header;
