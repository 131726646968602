import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeNetworkFilter } from '../../redux/actions/networkFilter';
import { Dropdown, Radio } from 'antd';
import arrowIconCpay from '../../img/cpay-svg/arrow-blue.svg';
import arrowIconNFG from '../../img/nfgpay-svg/arrow-blue-nfg.svg'
import './style.scss';
import { identity } from '../../utils/getIdentity';

export const dropdownIcon = {
  cpay: arrowIconCpay,
  nfg: arrowIconNFG
}


const NetworkFilter = ({ networkFilter, changeNetworkFilter }) => {
  const [isSortingVisible, setIsSortingVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="network-filter">
      <Dropdown
        onVisibleChange={visibilityState => setIsSortingVisible(visibilityState)}
        visible={isSortingVisible}
        placement="bottomCenter"
        overlay={
          <div className="network-filter__dropdown">
            <Radio.Group
              value={networkFilter}
              onChange={e => {
                changeNetworkFilter(e.target.value);
              }}
            >
              <Radio value="mainnet">{t('mainnet')}</Radio>
              <Radio value="testnet">{t('testnet')}</Radio>
            </Radio.Group>
          </div>
        }
      >
        <div className={`header__merc-button header__merc-button-${identity}`}>
          {window.location.pathname.includes('/admin') ? "Network filter" : t('header.networkFilter')}
          <img
            className={`network-filter__button-icon network-filter__button-${identity}-icon ${isSortingVisible ? `network-filter__button-icon_active`  : ''}`}
            src={dropdownIcon[identity]}
            alt="dropdown"
          />
        </div>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = state => ({
  networkFilter: state.networkFilter,
});

const mapDispatchToProps = dispatch => ({
  changeNetworkFilter: changeNetworkFilter(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkFilter);
