import React, { useState, useEffect, useRef } from 'react';
import DownloadAutosign from './DownloadAutosign';
import { ReactComponent as QRIcon } from '../../../img/default-svg/bx_qr.svg';
import { ReactComponent as Copy } from '../../../img/default-svg/copy.svg';
import QRCode from 'qrcode.react';
import { Popover, Tooltip, Button, Modal } from 'antd';
import TokenIcon from '../../TokenIcon';
import { useTranslation } from 'react-i18next';
import { identity } from '../../../utils/getIdentity';

const Replenish = ({ selectedWallet, setSelectedWallet, setOpenSettingsModal }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showAddressPopover, setShowAddressPopover] = useState(false);

  useEffect(() => {
    setSelectedWallet(selectedWallet);
  });

  console.log('wallet: ', selectedWallet);

  const { t } = useTranslation();

  const timer = useRef(null);
  const addressPopoverTimer = useRef(null);

  useEffect(() => {
    showPopover && setShowTooltip(false);
  }, [showPopover]);

  const copyHandler = (text, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 1000);
  };

  const addressCopyHandler = () => {
    navigator.clipboard.writeText('');
    setShowAddressPopover(true);
    addressPopoverTimer.current = setTimeout(() => setShowAddressPopover(false), 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(addressPopoverTimer);
      addressPopoverTimer.current = null;
    };
  });

  return (
    <div className="replenish">
      <DownloadAutosign />

      <div className="replenish__qr-block">
        <Popover
          title=""
          content={t('copied')}
          trigger="click"
          visible={showAddressPopover}
          className="replenish__qr-block__popover"
        >
          <div className="qr-block__address" onClick={addressCopyHandler}>
            <div>{`${selectedWallet.currency} ${t('address')}`}</div>
            <div className={`qr-block__copy-block qr-block__copy-block-${identity}`}>
              <div>{`${selectedWallet.address.slice(0, 4)}...${selectedWallet.address.slice(-4)}`}</div>
              <Copy />
            </div>
          </div>
        </Popover>
      </div>
      <QRCode style={{ margin: '0 auto' }} value={selectedWallet.address} size={205} />

      <Button
        type="primary"
        onClick={() => {
          setSelectedWallet(null);
          setOpenSettingsModal(false);
        }}
      >
        Ok
      </Button>
    </div>
  );
};

export default Replenish;
