import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SwapExchange from '../SwapExchange';
import { getSwapExchangeWallets, getSwapExchangeWallet } from '../../../redux/actions/swap';
import './style.scss';
import { getMerchantBalances } from '../../../redux/actions/merchantData';
import { getWithdrawWallets } from '../../../redux/actions/withdraw';

const SwapForm = ({
  merchantId,
  networkFilter,
  walletFromId,
  exchangeWalletsFrom,
  currenciesData,
  exchangeFields,
  setExchangeFields,
  getSwapExchangeWallets,
  swapOffers,
  priceForSwap,
  swapOffersFetching,
  swapOffersReset,
  swapOffersUpdating,
  polkaDotLimitWarning,
  setPolkaDotLimitWarning,
  dataIsFrozen,
  setDataIsFrozen,
  setCurrencyFieldIsEdited,
  currencyFieldIsEdited,
  getSwapExchangeWallet,
  isSwapStable,
  newCurrencyForStableSwap,
  getMerchantBalances,
  getWithdrawWallets,
  swapReceiveReset,
}) => {
  const [currencies, setCurrencies] = useState([]);
  const [socket, setSocket] = useState(undefined);

  useEffect(() => {
    setCurrencies(isSwapStable ? newCurrencyForStableSwap : currenciesData);
  }, [currenciesData, newCurrencyForStableSwap]);

  useEffect(() => {
    if (currencies.length && !exchangeFields[0].selectedCurrency && !exchangeFields[1].selectedCurrency) {
      setExchangeFields([
        {
          ...exchangeFields[0],
          selectedCurrency: currencies[0]._id,
        },
        {
          ...exchangeFields[1],
          selectedCurrency: currencies[1]._id,
        },
      ]);
    }
  }, [currencies]);

  const findItemInCurrencyArray = (itemArr, arr, itemToFind) => {
    for (const item of itemArr) {
      const selectedCurrencyId = item.selectedCurrency;

      const foundCurrency = arr.find(currency => currency._id === selectedCurrencyId);

      if (foundCurrency) {
        if (itemToFind === 'chainSymbol') {
          return foundCurrency?.chainSymbol;
        } else if (itemToFind === 'name') {
          return foundCurrency?.name;
        } else {
          return foundCurrency?.contractAddress?.mainnet;
        }
      }
    }
  };

  useEffect(() => {
    if (isSwapStable) {
      const firstChainSymbol = findItemInCurrencyArray([exchangeFields[0]], currencies, 'chainSymbol');
      const secondChainSymbol = findItemInCurrencyArray([exchangeFields[1]], currencies, 'chainSymbol');
      const firstContractAddress = findItemInCurrencyArray([exchangeFields[0]], currencies, 'contractAddress');
      const secondContractAddress = findItemInCurrencyArray([exchangeFields[1]], currencies, 'contractAddress');
      const firstName = findItemInCurrencyArray([exchangeFields[0]], currencies, 'name');
      const secondName = findItemInCurrencyArray([exchangeFields[1]], currencies, 'name');

      setExchangeFields([
        {
          ...exchangeFields[0],

          chainSymbol: firstChainSymbol || exchangeFields[0].chainSymbol || '',
          contractAddress: firstContractAddress || exchangeFields[0].contractAddress || '',
          name: firstName || exchangeFields[0].name || '',
        },
        {
          ...exchangeFields[1],

          chainSymbol: secondChainSymbol || exchangeFields[1].chainSymbol || '',
          contractAddress: secondContractAddress || exchangeFields[1].contractAddress || '',
          name: secondName || exchangeFields[1].name || '',
        },
      ]);
    } else {
      setExchangeFields([
        {
          ...exchangeFields[0],
        },
        {
          ...exchangeFields[1],
        },
      ]);
    }
  }, [exchangeFields[0].selectedCurrency, exchangeFields[1].selectedCurrency]);

  useEffect(() => {
    if (merchantId && networkFilter && exchangeFields[0].selectedCurrency && exchangeFields[1].selectedCurrency) {
      const tokenFromMainCurrency = currencies.find(
        currency => currency._id === exchangeFields[0].selectedCurrency
      )?.mainCurrency;

      const tokenToMainCurrency = currencies.find(
        currency => currency._id === exchangeFields[1].selectedCurrency
      )?.mainCurrency;

      if (!dataIsFrozen) {
        if (currencyFieldIsEdited === 'none' || currencyFieldIsEdited === 'both') {
          getSwapExchangeWallets(merchantId, {
            limit: -1,
            typeWallet: 'merchant',
            typeNetwork: networkFilter,
            currencyIds: [
              `${exchangeFields[0].selectedCurrency}${tokenFromMainCurrency ? `,${tokenFromMainCurrency}` : ''}`,
              `${exchangeFields[1].selectedCurrency}${tokenToMainCurrency ? `,${tokenToMainCurrency}` : ''}`,
            ],
          });
          return;
        }

        const token = currencies.find(currency => {
          return currency._id === exchangeFields[currencyFieldIsEdited].selectedCurrency;
        })?.mainCurrency;

        getSwapExchangeWallet(
          merchantId,
          {
            limit: -1,
            typeWallet: 'merchant',
            typeNetwork: networkFilter,
            currencyIds: `${exchangeFields[currencyFieldIsEdited].selectedCurrency}${token ? `,${token}` : ''}`,
          },
          currencyFieldIsEdited
        );
      }
    }
  }, [
    merchantId,
    networkFilter,
    exchangeFields[0].selectedCurrency,
    exchangeFields[1].selectedCurrency,
    currencyFieldIsEdited,
    currencies,
  ]);

  // useEffect(() => {
  //   socket && socket.disconnect();
  //   setSocket(undefined);
  //   createSocket(merchantId);
  // }, [merchantId]);


  // const createSocket = merchantId => {
  //   const io = window.io;
  //   const socket = io(process.env.REACT_APP_API_URL, {
  //     allowEIO3: true,
  //     withCredentials: true,
  //   });
  //   socket.on('connect', () => {
  //     socket.emit('sign-in-merchant', { token: localStorage.getItem('authToken'), merchantId });
  //     setSocket(socket);
  //   });
  //   socket.on('updatedBalance', () => {
  //     const token = currencies.find(currency => {
  //       return currency._id === exchangeFields[currencyFieldIsEdited].selectedCurrency;
  //     })?.mainCurrency;

  //     if (merchantId) {
  //       getSwapExchangeWallet(
  //         merchantId,
  //         {
  //           limit: -1,
  //           typeWallet: 'merchant',
  //           typeNetwork: networkFilter,
  //           currencyIds: `${exchangeFields[currencyFieldIsEdited].selectedCurrency}${token ? `,${token}` : ''}` || undefined,
  //         },
  //         currencyFieldIsEdited
  //       );
  //     }
  //   });
  //   socket.on('disconnect', function () {});
  // };

  return (
    <SwapExchange
      currencies={currencies}
      exchangeFields={exchangeFields}
      setExchangeFields={setExchangeFields}
      merchantId={merchantId}
      networkFilter={networkFilter}
      walletFromId={walletFromId}
      exchangeWalletsFrom={exchangeWalletsFrom}
      swapOffers={swapOffers}
      priceForSwap={priceForSwap}
      swapOffersFetching={swapOffersFetching}
      swapOffersReset={swapOffersReset}
      swapReceiveReset={swapReceiveReset}
      swapOffersUpdating={swapOffersUpdating}
      polkaDotLimitWarning={polkaDotLimitWarning}
      setPolkaDotLimitWarning={setPolkaDotLimitWarning}
      setDataIsFrozen={setDataIsFrozen}
      setCurrencyFieldIsEdited={setCurrencyFieldIsEdited}
      currencyFieldIsEdited={currencyFieldIsEdited}
      isSwapStable={isSwapStable}
    />
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  networkFilter: state.networkFilter,
  currenciesData: state.currencies.data,
});

const mapDispatchToProps = dispatch => ({
  getSwapExchangeWallets: dispatch(getSwapExchangeWallets),
  getSwapExchangeWallet: dispatch(getSwapExchangeWallet),
  getMerchantBalances: getMerchantBalances(dispatch),
  getWithdrawWallets: getWithdrawWallets(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapForm);
