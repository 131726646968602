import React, { useEffect, useState, useRef } from 'react';
import { Select, Popover, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import TokenIcon from '../../TokenIcon';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as CopyIcon } from '../../../img/default-svg/copy.svg';
import './style.scss';

const TransactionOffer = ({ currencyId, merchantBalances }) => {
  const [selectedCurrencyId, setSelectedCurrencyId] = useState(currencyId || undefined);
  const [selectedCurrency, setSelectedCurrency] = useState(undefined);
  const [showAddressPopover, setShowAddressPopover] = useState(false);
  const addressPopoverTimer = useRef(null);
  const {t} = useTranslation();

  useEffect(() => {
    if (merchantBalances.length) {
      setSelectedCurrencyId(merchantBalances[0].currency.id);
      setSelectedCurrency(merchantBalances[0]);
    }
  }, [merchantBalances]);

  useEffect(() => {
    if (currencyId) {
      setSelectedCurrencyId(currencyId);
      setSelectedCurrency(merchantBalances.find(merchantBalance => merchantBalance.currency.id === selectedCurrencyId));
    }
  }, [currencyId]);

  useEffect(() => {
    setSelectedCurrency(merchantBalances.find(merchantBalance => merchantBalance.currency.id === selectedCurrencyId));
  }, [selectedCurrencyId]);

  useEffect(() => {
    return () => {
      clearTimeout(addressPopoverTimer);
      addressPopoverTimer.current = null;
    };
  }, []);

  const truncate = function (fullStr = '', strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
  };

  const addressCopyHandler = () => {
    navigator.clipboard.writeText(selectedCurrency.wallet);
    setShowAddressPopover(true);
    addressPopoverTimer.current = setTimeout(() => setShowAddressPopover(false), 1000);
  };

  return (
    <div className="transaction-offer">
      <div className="transaction-offer__title">{t('transactionOffer.title')}</div>
      {merchantBalances.length ? (
        <>
          <div className="transaction-offer__selector">
          {t('transactionOffer.copy')}{' '}
            <Select
              className="transaction-offer__select"
              value={selectedCurrencyId}
              onChange={value => {
                setSelectedCurrencyId(value);
              }}
            >
              {merchantBalances.map(merchantBalance => (
                <Select.Option
                  value={merchantBalance.currency.id}
                  className="transaction-offer__select-option"
                  key={merchantBalance.currency.id}
                  style={{padding: 10}}
                >
                  <TokenIcon className="currency-item__logo" tokenName={merchantBalance.currency.name} />
                  {merchantBalance.currency.title}
                </Select.Option>
              ))}
            </Select>{' '}
            {t('transactionOffer.address')}
          </div>
          {selectedCurrency && selectedCurrency.wallet && (
            <Popover
              title=""
              content={t('copied')}
              trigger="click"
              visible={showAddressPopover}
              className="detailed-checkout__popover"
            >
              <div className="transaction-offer__wallet">
                <div>
                  <TokenIcon className="currency-item__logo" tokenName={selectedCurrency.currency.name} />
                  {truncate(selectedCurrency.wallet, 14, '..')}
                </div>
                <CopyIcon className="transaction-offer__wallet-icon" onClick={addressCopyHandler} />
              </div>
            </Popover>
          )}
        </>
      ) : (
        <Spin className="transaction-offer__loader" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      )}
    </div>
  );
};

export default TransactionOffer;
