import React from 'react';
import { useTranslation } from 'react-i18next';
import backCpayIcon from '../img/cpay-svg/arrow-back.svg';
import backNFGIcon from '../img/nfgpay-svg/arrow-back-nfg.svg';
import { Link } from 'react-router-dom';
import './PageHeader.scss';
import { identity } from '../utils/getIdentity';

const backIcon = {
  cpay: backCpayIcon,
  nfg: backNFGIcon
}

export default function PageHeader({ pageHeaderText, CreateModalComponent, showBackButton }) {
  const { t } = useTranslation();

  return (
    <div className="page-header" align="middle">
      {CreateModalComponent && <CreateModalComponent />}
      {pageHeaderText && <p className="page-header__tip">{pageHeaderText}</p>}
      {CreateModalComponent && showBackButton && (
        <Link to="/merchants" className={`page-header__back page-header__back-${identity}`}>
          <img src={backIcon[identity]} alt="Back" />
          <span>{t('goBack')}</span>
        </Link>
      )}
    </div>
  );
}
