import React, { useEffect, useState, useRef } from "react";
import { Modal, Popover, Select } from "antd";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import { getMerchantWallets } from "../../../redux/actions/merchantWallets";
import { truncate } from "../../../utils/truncateFunction";
import { CustomSelectOption } from "./CustomSelectOption";
import "./styles.scss";

const ReplenishModal = ({
  openReplenish,
  handleCloseReplenish,
  merchantId,
  networkFilter,
  merchantWallets,
  getMerchantWallets,
  currencyId,
  merchantBalances,
}) => {
  const { t } = useTranslation();

  const [selectedCurrencyId, setSelectedCurrencyId] = useState(currencyId || undefined);
  const [selectedCurrency, setSelectedCurrency] = useState(undefined);
  const [selectedWallet, setSelectedWallet] = useState(undefined);
  const [showPopover, setShowPopover] = useState(false);
  const [walletsByMerchant, setWalletsByMerchant] = useState(merchantWallets);
  const [walletsByCurrency, setWalletsByCurrency] = useState([]);
  const [disabledWallets, setDisabledWallets] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    setWalletsByMerchant(merchantWallets);
  }, [merchantWallets]);

  useEffect(() => {
    if (selectedCurrency && selectedCurrencyId && walletsByMerchant) {
      const myWallets = walletsByMerchant?.filter((w) => w.currencyId === selectedCurrencyId) ?? [];
      const myWalletsWithTokens = walletsByMerchant?.filter((w) => w.tokens.length > 0) ?? [];
      const walletsWithTokensOrder =
        myWalletsWithTokens?.map((w) => {
          return {
            ...w,
            balance: w.tokens[0].balance,
            currency: w.tokens[0].currency,
            currencyId: w.tokens[0].currencyId,
          };
        }) ?? [];
      const myWalletsByCyrrency = [...myWallets, ...walletsWithTokensOrder];
      setWalletsByCurrency(myWalletsByCyrrency);
    }
  }, [selectedCurrency, selectedCurrencyId, walletsByMerchant]);

  useEffect(() => () => {
    clearTimeout(timer);
    timer.current = null;
  });

  const getNewWalletList = async() => {
    setDisabledWallets(true);
    await getMerchantWallets(merchantId, {
      typeNetwork: networkFilter,
      limit: -1,
      currencyIds: selectedCurrencyId,
      typeWallet: "merchant",
      positiveBalance: false,
    });

    setDisabledWallets(false);
  }

  useEffect(() => {
    if (merchantId && selectedCurrencyId) {
      getNewWalletList();
    }
  }, [merchantId, networkFilter, selectedCurrencyId]);

  useEffect(() => {
    if (currencyId) {
      setSelectedCurrencyId(currencyId);
      setSelectedCurrency(merchantBalances.find((merchantBalance) => merchantBalance.currency.id === currencyId));
    }
  }, [currencyId]);

  useEffect(() => {
    setSelectedCurrency(merchantBalances.find((merchantBalance) => merchantBalance.currency.id === selectedCurrencyId));
  }, [selectedCurrencyId]);

  useEffect(() => {
    if (selectedWallet) {
      setSelectedWallet(selectedWallet);
    }
  }, [selectedWallet]);

  const closeReplenishModal = () => {
    handleCloseReplenish();
  };

  const copyHandler = (text, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(selectedWallet);
    setShowPopover(true);
    timer.current = setTimeout(() => setShowPopover(false), 1000);
  };

  const selectedCurrencyValue = selectedCurrency && (
    <CustomSelectOption
      name={selectedCurrency.currency.name}
      title={selectedCurrency.currency.title}
      balanceValue={selectedCurrency.balance.value}
      balanceUsd={selectedCurrency.balance.usd}
      identity="currency"
    />
  );

  console.log(selectedCurrency)

  return (
    <Modal className="replenish__modal" title={t("merchants.replenish")} width={446} visible={openReplenish} footer={null} onCancel={closeReplenishModal}>
      <div className="replenish__modal-container">
        <div className="replenish__modal-container__currency">
          <span>{t("currency")}</span>
          <Select
            value={selectedCurrency ? selectedCurrencyValue : t("wallets.chooseCurrency")}
            onChange={(value) => {
              setSelectedCurrencyId(value);
              setSelectedWallet(undefined);
              setSelectedCurrency(undefined);
              setWalletsByCurrency([]);
            }}
            className={`replenish__modal-container__selected-${selectedCurrency ? "active" : "initial"}`}
            showSearch
            filterOption={(input, option) =>
              (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {merchantBalances?.map((currency) => {
              return (
                <Select.Option key={currency.currency.id}  title={currency.currency.title} value={currency.currency.id}>
                  <CustomSelectOption
                    name={currency.currency.name}
                    title={currency.currency.title}
                    balanceValue={currency.balance.value}
                    balanceUsd={currency.balance.usd}
                    identity="currency"
                  />
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className="replenish__modal-container__address">
          <span>{t("wallets.walletAddress")}</span>
          <Select
            disabled={!selectedCurrency || disabledWallets}
            value={selectedWallet ?? t("multisendWithdraw.chooseWalletAddress")}
            onChange={(value) => {
              setSelectedWallet(value);
            }}
            className={`replenish__modal-container__selected-${selectedWallet ? "active" : "initial"}`}
          >
            {selectedCurrency &&
              walletsByCurrency?.length > 0 &&
              walletsByCurrency?.map((wallet) => {
                return (
                  <Select.Option className="replenish__modal-container__select" value={wallet?.address} key={wallet._id}>
                    <CustomSelectOption
                      name={wallet.currency}
                      title={truncate(wallet.address, 14)}
                      balanceValue={wallet.balance.value}
                      balanceUsd={wallet.balance.usd}
                      identity="wallet"
                      currencyType={wallet.currencyType}
                      nodeType={wallet.nodeType}
                    />
                  </Select.Option>
                );
              })}
          </Select>
        </div>

        {selectedWallet ? (
          <div className="replenish__modal-container-qr">
            <QRCode value={selectedWallet} size={270} />
          </div>
        ) : null}
        {selectedWallet ? (
          <Popover content={t("copied")} trigger="click" visible={showPopover} hidden={true}>
            <Button type="secondary" className="replenish__modal-container__copy" onClick={(event) => copyHandler(selectedWallet, event)}>
              {t("copyAddress")}
            </Button>
          </Popover>
        ) : null}
        <Button className="replenish__modal-container__ok" onClick={closeReplenishModal}>
          OK
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  merchantWallets: state.merchantWallets.data,
});

const mapDispatchToProps = (dispatch) => ({
  getMerchantWallets: getMerchantWallets(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplenishModal);
