import React from 'react';
import { Link } from 'react-router-dom';
import './PageNotFound.scss';
import logoCpay from '../../img/footer/footerLogo.svg';
import logoNfg from '../../img/nfgpay-svg/nfg_logo_black.svg'
import { identity } from '../../utils/getIdentity';

const logos = {
  cpay: logoCpay,
  nfg: logoNfg
}

const PageNotFound = () => (
  <div className="not-found">
    <div className="not-found__box">
      <div className="not-found__header">
        <Link to="/">
          <img src={logos[identity]} alt="logo" />
        </Link>
      </div>
      <div className="not-found__main-block">
        <span>404</span>
        <div className="not-found__main-block-text">
          <h3>Page not found...</h3>
          <p>
            Please check that you have entered in the correct address, you could also return to the homepage and try
            again.
          </p>
        </div>
        <Link className={`not-found__button not-found__button-${identity}`} to="/">
          Back to Home page
        </Link>
      </div>
    </div>
  </div>
);

export default PageNotFound;
