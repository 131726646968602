import React, { useState } from 'react';
import TokenIcon from '../../TokenIcon';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReactComponent as ArrowRightBlue } from '../../../img/cpay-svg/arrow-right-blue.svg';
import { ReactComponent as ArrowRightNFGBlue } from '../../../img/nfgpay-svg/arrow-right-blue-nfg.svg';
import { ReactComponent as FloatingIcon } from '../../../img/swap-svg/swap-floating.svg';
import { ReactComponent as FixedIcon } from '../../../img/swap-svg/swap-fixed.svg';
import getSwapPartnerIcon from '../../../utils/swapPartnerIcons';
import { getHashFromLink } from '../../../utils/getHashFromLink';
import moment from 'moment';
import { tokenNodeTypeObj, tokenTypeEnd } from '../../../utils/tokenNodeTypes';
import './style.scss';
import { identity } from '../../../utils/getIdentity';
import { ReactComponent as CopyIconCpay } from '../../../img/cpay-svg/copy-blue.svg';
import { ReactComponent as CopyIconNfg } from '../../../img/nfgpay-svg/copy-blue-nfg.svg';
import CopyWrapper from '../../CopyWrapper';

const copyIcon = {
  cpay: <CopyIconCpay />,
  nfg: <CopyIconNfg />,
};

const arrowRightIcon = {
  cpay: <ArrowRightBlue />,
  nfg: <ArrowRightNFGBlue />,
};

const SwapItem = ({ swapItem, currencies, networkFilter }) => {
  const [isDetailsOpened, setIsDetailsOpened] = useState(false);
  const { t } = useTranslation();

  const swapStatus = field => {
    const values = {
      Done: t('swap.swapStatus.done'),
      Failed: t('swap.swapStatus.failed'),
      Pending: t('swap.swapStatus.pending'),
      New: t('swap.swapStatus.new'),
    };

    return values[field];
  };

  const currencyFrom = currencies.find(currency => currency._id === swapItem.from.currency.id);
  const currencyTo = currencies.find(currency => currency._id === swapItem.to.currency.id);

  return (
    <>
      <div
        className={cn('swap-history-item', {
          'swap-history-item_opened': isDetailsOpened,
        })}
      >
        <div className="swap-history-item__main-data" onClick={() => setIsDetailsOpened(!isDetailsOpened)}>
          <div className="swap-history-item__id">{swapItem.exchangeId}</div>
          <div className="swap-history-item__date">
            <span>{moment(swapItem.createdAt).format('DD.MM.YYYY')}</span>
            <span className="swap-history-item__time">{moment(swapItem.createdAt).format('HH:mm')}</span>
          </div>
          <div className="swap-history-item__currency">
            <div className="swap-history-item__currency-item">
              <div className="swap-history-item__currency-icon">
                <TokenIcon tokenName={swapItem.from.currency.name} />
              </div>
              {swapItem.from.amount.value} {swapItem.from.currency.name}{' '}
              {currencyFrom.currencyType === 'token' ? tokenNodeTypeObj[currencyFrom.nodeType] : null}
              {tokenTypeEnd(currencyFrom.currencyType, currencyFrom.nodeType, currencyFrom.name)}
            </div>
            {arrowRightIcon[identity]}
            <div className="swap-history-item__currency-item">
              <div className="swap-history-item__currency-icon">
                <TokenIcon tokenName={swapItem.to.currency.name} />
              </div>
              {swapItem.to.amount.value} {swapItem.to.currency.name}{' '}
              {currencyTo.currencyType === 'token' ? tokenNodeTypeObj[currencyTo.nodeType] : null}
              {tokenTypeEnd(currencyTo.currencyType, currencyTo.nodeType, currencyTo.name)}
            </div>
          </div>
          <div className={`swap-history-item__status swap-history-item__status_${swapItem.systemStatus.toLowerCase()}`}>
            <span>{swapStatus(swapItem.systemStatus)}</span>
          </div>
        </div>

        <div
          className={cn('swap-history-details', {
            'swap-history-details_opened': isDetailsOpened,
          })}
        >
          <div className="swap-history-details__partner">
            <div className="swap-history-details__partner-title">{t('swap.offerItem.partner')}:</div>
            <div className="swap-history-details__partner-value">
              <img src={getSwapPartnerIcon(swapItem.partner)} alt={swapItem.partner} /> {swapItem.partner}
            </div>
          </div>
          <div className="swap-history-details__type">
            <div className="swap-history-details__type-title">{t('swap.offerItem.type')}:</div>
            {swapItem.fixed && (swapItem.rateId === undefined || swapItem.rateId) ? (
              <div className="swap-history-details__type-value">
                <FixedIcon />
                {t('swap.offerItem.fixed')}
              </div>
            ) : (
              <div className="swap-history-details__type-value">
                <FloatingIcon />
                {t('swap.offerItem.floating')}
              </div>
            )}
          </div>

          <div className="swap-history-details__card">
            <div className="swap-history-details__card-title">{t('swap.offerItem.from')}</div>
            <div className="swap-history-details__wallet">
              <span className="swap-history-details__wallet-title">{t('swap.offerItem.wallet')}:</span>{' '}
              <span className={`swap-history-details__wallet-value`}>
                {swapItem.from.address}
                <CopyWrapper copyContent={swapItem.from.address}>{copyIcon[identity]}</CopyWrapper>
              </span>
            </div>
            <div className="swap-history-details__paymentId">
              <span className="swap-history-details__paymentId-title">PaymentId:</span>{' '}
              <span className="swap-history-details__paymentId-value">{swapItem.from.paymentId}</span>
            </div>
            <div className="swap-history-details__amount">
              <span className="swap-history-details__amount-title">{t('swap.offerItem.amount')}:</span>{' '}
              <span className="swap-history-details__amount-value">
                {swapItem.from.amount.value} {swapItem.from.currency.name} ⸺ ${swapItem.from.amount.usd}
              </span>
            </div>
            {swapItem.from.explorerUrl && swapItem.from.hash !== null ? (
              <div className="swap-history-details__hash">
                <span className="swap-history-details__hash-title">Hash:</span>{' '}
                <span className={`swap-history-details__hash-value swap-history-details__hash-value-${identity}`}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${swapItem.from.explorerUrl}`}
                    className={`link link-${identity}`}
                  >
                    {getHashFromLink(swapItem.from.explorerUrl)}
                  </a>
                  <CopyWrapper copyContent={getHashFromLink(swapItem.from.explorerUrl)}>
                    {copyIcon[identity]}
                  </CopyWrapper>
                </span>
              </div>
            ) : null}
          </div>

          <div className="swap-history-details__card">
            <div className="swap-history-details__card-title">{t('swap.offerItem.to')}</div>
            <div className="swap-history-details__wallet">
              <span className="swap-history-details__wallet-title">{t('swap.offerItem.wallet')}:</span>{' '}
              <span className="swap-history-details__wallet-value">
                {swapItem.to.address} <CopyWrapper copyContent={swapItem.to.address}>{copyIcon[identity]}</CopyWrapper>
              </span>
            </div>
            <div className="swap-history-details__paymentId">
              <span className="swap-history-details__paymentId-title">PaymentId:</span>{' '}
              <span className="swap-history-details__paymentId-value">{swapItem.to.paymentId}</span>
            </div>
            <div className="swap-history-details__amount">
              <span className="swap-history-details__amount-title">{t('swap.offerItem.amount')}:</span>{' '}
              <span className="swap-history-details__amount-value">
                {swapItem.to.amount.value} {swapItem.to.currency.name} ⸺ ${swapItem.to.amount.usd}
              </span>
            </div>
            {swapItem.to.explorerUrl && swapItem.to.hash !== null ? (
              <div className="swap-history-details__hash">
                <span className="swap-history-details__hash-title">Hash:</span>{' '}
                <span className="swap-history-details__hash-value">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${swapItem.to.explorerUrl}`}
                    className={`link link-${identity}`}
                  >
                    {getHashFromLink(swapItem.to.explorerUrl)}
                  </a>
                  <CopyWrapper copyContent={getHashFromLink(swapItem.to.explorerUrl)}>{copyIcon[identity]}</CopyWrapper>
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapItem;
