import React, { useState } from 'react';
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import { Row, Col, Modal, Form, Input } from 'antd';
import Button from '../Button';
import { getTwoFactor, confirmTwoFactor } from '../../redux/actions/twoFactor';
import { changeTwoFactorStatus } from '../../redux/actions/user';
import { useTranslation, Trans } from 'react-i18next';
import '../../layout/Modal.scss';
import './style.scss'
import { identity } from '../../utils/getIdentity';

const AppTwoFaEnableModal = ({
  appTwoFaFetching,
  twoFactorData,
  getTwoFactor,
  confirmTwoFactor,
  changeTwoFactorStatus,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const { t } = useTranslation();

  const handleOpen = () => {
    getTwoFactor();
    setIsVisible(true);
  };

  const handleSubmit = async () => {
    const response = await confirmTwoFactor(twoFactorCode);
    if (response) {
      changeTwoFactorStatus(true);
      setIsVisible(false);
    }
  };

  const handleCancel = e => {
    setIsVisible(false);
  };

  return (
    <React.Fragment>
      <Button type="primary" className="form__button" onClick={handleOpen}>
        {t('profile.enableTwoFa')}
      </Button>
      <Modal
        width={534}
        title={t('profile.enableTwoFa')}
        visible={isVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <div className='enable-twofa'>
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          className="modal-form"
          rules={[{ required: true, message: t('validation.required') }]}
          onFinish={handleSubmit}
        >
          <div className="form__subtitle">
            <Trans i18nKey="profile.scanQrCode">
              Scan this QR Code with <b>Google Authenticator</b> and put 6 digit code here.
            </Trans>
          </div>
          {twoFactorData.url && twoFactorData.url.length > 0 && (
            <QRCode className="two-factor-qr" value={twoFactorData.url} size={130} />
          )}
          <div className="form__subtitle">
            <b>{t('auth.keyFromGA')}</b>: {twoFactorData.secret}
          </div>
          <Form.Item
            label={t('profile.authenticatorCode')}
            name="authCode"
            className="modal-form__label"
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input
              placeholder={t('auth.keyFromGA')}
              className="modal-form__input"
              maxLength="6"
              onChange={e => setTwoFactorCode(e.target.value)}
            />
          </Form.Item>
          <Row className="modal__buttons-wrapper">
            <Col span="12">
              <Button
                type="primary"
                className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
                loading={appTwoFaFetching}
                disabled={appTwoFaFetching}
              >
                {t('profile.enableTwoFa')}
              </Button>
            </Col>
            <Col span="12">
              <Button
                type="primary"
                className="modal__button modal__button-cancel"
                onClick={e => {
                  e.preventDefault();
                  handleCancel();
                }}
                disabled={appTwoFaFetching}
              >
                {t('cancel')}
              </Button>
            </Col>
          </Row>
        </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  twoFactorData: state.twoFactor.data,
  appTwoFaFetching: state.twoFactor.appTwoFaFetching,
});

const mapDispatchToProps = dispatch => ({
  getTwoFactor: getTwoFactor(dispatch),
  confirmTwoFactor: confirmTwoFactor(dispatch),
  changeTwoFactorStatus: changeTwoFactorStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppTwoFaEnableModal);
