import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import NotFound from '../components/CheckoutPayment/NotFound';
import qs from 'qs';
import { createChargeId } from '../redux/actions/checkoutPayment';
import { useParams, useLocation } from 'react-router-dom';
import { AppConfig } from '../config';
import { Helmet } from 'react-helmet';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CheckoutCreator = ({ chargeIdState, createChargeId }) => {
  const [params, setParams] = useState('');
  const query = useQuery();
  const { identifier } = useParams();

  useEffect(() => {
    let obj = {};
    for (var pair of query.entries()) {
      obj[pair[0]] = pair[1];
    }
    setParams(qs.stringify(obj));
  }, []);

  useEffect(() => {
    createChargeId(identifier, query.get('clickId'));
  }, [identifier]);

  return (
    <>
      {AppConfig.identity === 'cpay' && (
          <Helmet>
            <link rel="icon" href="/cpay-icons/favicon.ico" type="image/x-icon" />
          </Helmet>
        )}

        {AppConfig.identity === 'nfg' && (
          <Helmet>
          <link rel="icon" href="/nfgpay-icons/favicon.ico" type="image/x-icon" />
        </Helmet>
        )}

    {
      chargeIdState.data ? (
        <Redirect to={`/charge/${chargeIdState.data}${params ? `?${params}` : ''}`} />
      ) : (
        chargeIdState.error && <NotFound title="Checkout" />
      )
    }
</>
  )
  
};

const mapStateToProps = state => ({
  chargeIdState: state.checkoutPayment.chargeId,
});

const mapDispatchToProps = {
  createChargeId,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCreator);
