import React from 'react';
import TokenIcon from '../../../../TokenIcon';

import formatCurrency from '../../../../../utils/currencyFormatter';

import './style.scss';
import { identity } from '../../../../../utils/getIdentity';

const CurrencyItem = ({ currency, setCurrency, currencyItem, currencyRef }) => {

  return (
    <div
      className={`withdraw-currency-item ${currency === currencyItem.currency.id && 'withdraw-currency-item_active'}`}
      onClick={() =>  {
        currencyRef.current = currency;
        setCurrency(currencyItem.currency.id);
      }}
    >
      <div className="withdraw-currency-item_left">
        <div className="currency-item__name-wrap">
          <TokenIcon className="withdraw-currency-item__logo" tokenName={currencyItem.currency.name} />
          <div className="withdraw-currency-item__title">{currencyItem.currency.title}</div>
        </div>
      </div>
      {currencyItem &&
       <div className="withdraw-currency-item_right">
       <div className={`withdraw-currency-item__amount withdraw-currency-item__amount-${identity}`}>
         {currencyItem?.balance?.value ?? 0} {currencyItem.currency.name}
       </div>
       <div className="withdraw-currency-item__usd">{formatCurrency(currencyItem?.balance?.usd) ?? 0}</div>
     </div>}

    </div>
  );
};

export default CurrencyItem;
