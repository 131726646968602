import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { translateError, extractAmountFromErrorMsg } from './helpers';
import SwapNewCard from '../SwapNew/SwapNewCard';
import TokenIcon from '../TokenIcon';
import {
  makeSwap,
  makeStableSwap,
  swapResetTwoFa,
  resetStableSwapError,
  resetExchangeError,
} from '../../redux/actions/swap';
import { Modal } from 'antd';
import Button from '../Button';
import { ReactComponent as ArrowRightIcon } from '../../img/cpay-svg/arrow-right-blue.svg';
import { ReactComponent as SwapTickSuccessIcon } from '../../img/swap-svg/swap-tick-success.svg';
import { ReactComponent as SwapTickFailIcon } from '../../img/swap-svg/swap-tick-fail.svg';
import { ReactComponent as FloatingIcon } from '../../img/swap-svg/swap-floating.svg';
import { ReactComponent as FixedIcon } from '../../img/swap-svg/swap-fixed.svg';
import { ReactComponent as ChartIcon } from '../../img/default-svg/chart-icon.svg';
import getSwapPartnerIcon from '../../utils/swapPartnerIcons';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { identity } from '../../utils/getIdentity';
import SwapTwoFaConfirm from '../SwapTwoFaConfirm';
import { getDecimalValue } from '../../utils/getDecimalValue';

const SwapConfirm = ({
  fromId,
  toId,
  currencyFromId,
  currencyToId,
  amount,
  summaryRate,
  setSummaryRate,
  partner,
  fixed,
  currencies,
  makeSwap,
  swapData,
  swapError,
  swapFetching,
  swapOffers,
  updatedOffers,
  swapOffersReset,
  selectedPartnerRateId,
  setSwapOffersType,
  setSelectedPartner,
  setSelectedPartnerRateId,
  setShowSwapOffers,
  updateSwapOffers,
  swapOffersUpdateApply,
  swapOffersUpdating,
  sort,
  type,
  handleErrorMsgClick,
  setSwapAmountFromError,
  isSwapStable,
  exchangeFields,
  newCurrencyForSwap,
  priceForSwap,
  makeStableSwap,
  makeStableSwapFetching,
  makeStableSwapError,
  makeStableSwapData,
  swapReceiveReset,
  swapResetTwoFa,
  resetStableSwapError,
  resetExchangeError,
}) => {
  const [showUpdateRatesBanner, setShowUpdateRatesBanner] = useState(false);
  const [currencyFromName, setCurrencyFromName] = useState('');
  const [currencyToName, setCurrencyToName] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showTwoFaModal, setShowTwoFaModal] = useState(false);
  const history = useHistory();
  const offersUpdateTimer = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelector('.main-layout__content-wrap').scrollTo(0, 0);
  }, []);

  useEffect(() => () => offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current), []);

  useEffect(() => {
    if (!isSwapStable) {
      if (showConfirmModal) {
        offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current);
      } else {
        setUpdateTimer();
      }
    }
  }, [showConfirmModal]);

  useEffect(() => {
    if (currencies && currencies.length && currencyFromId && currencyToId) {
      const fromCurrency = currencies.find(currency => currency._id === currencyFromId);
      const toCurrency = currencies.find(currency => currency._id === currencyToId);
      setCurrencyFromName(fromCurrency);
      setCurrencyToName(toCurrency);
    }
  }, [currencies, currencyFromId, currencyToId]);

  useEffect(() => {
    if (!isSwapStable && updatedOffers && updatedOffers.length) {
      const currentOffer = swapOffers.find(offer => offer.partner === partner && offer.fixed === fixed);
      const updatedOffer = updatedOffers.find(offer => offer.partner === partner && offer.fixed === fixed);

      let hasDifference = false;

      const objA = {
        duration: updatedOffer.duration,
        fixed: updatedOffer.fixed,
        partner: updatedOffer.partner,
        toAmount: updatedOffer.toAmount,
      };

      const objB = {
        duration: currentOffer.duration,
        fixed: currentOffer.fixed,
        partner: currentOffer.partner,
        toAmount: currentOffer.toAmount,
      };

      if (
        objA.duration !== objB.duration ||
        objA.fixed !== objB.fixed ||
        objA.partner !== objB.partner ||
        objA.toAmount !== objB.toAmount
      ) {
        hasDifference = true;
      }

      if (hasDifference) setShowUpdateRatesBanner(true);
    }
  }, [updatedOffers]);

  useEffect(() => {
    !isSwapStable &&
      swapOffers &&
      swapOffers.length &&
      setSummaryRate(swapOffers.find(offer => offer.partner === partner && offer.fixed === fixed).toAmount);
  }, [swapOffers]);

  const setUpdateTimer = () => {
    setShowUpdateRatesBanner(false);
    offersUpdateTimer.current && clearTimeout(offersUpdateTimer.current);
    offersUpdateTimer.current = setInterval(
      () => updateSwapOffers({ fromId: currencyFromId, toId: currencyToId, amount, sort, type }),
      15000
    );
  };
  const isShowErrorModalAfter2Fa = errorText => {
    const includedTexts = [
      'Wrong Verification Code',
      'Wrong E-mail 2FA',
      'Wrong 2FA key',
      'Please wait less than a minute',
    ];
    return !includedTexts.includes(errorText);
  };

  useEffect(() => {
    if (swapError) {
      extractAmountFromErrorMsg(swapError, setSwapAmountFromError);
    }
    if (makeStableSwapError) {
      extractAmountFromErrorMsg(makeStableSwapError, setSwapAmountFromError);
    }
    if (swapError || makeStableSwapError) {
      if (!!isShowErrorModalAfter2Fa(swapError || makeStableSwapError)) {
        setShowTwoFaModal(false);
        swapResetTwoFa();
        setShowConfirmModal(true);
      }
    }
  }, [swapError, makeStableSwapError]);

  return (
    <>
      <SwapTwoFaConfirm
        isVisible={showTwoFaModal}
        setIsVisible={setShowTwoFaModal}
        action={!isSwapStable ? makeSwap : makeStableSwap}
        fetching={makeStableSwapFetching}
        clearWithdrawState={setShowConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        body={
          !isSwapStable
            ? {
                fromId,
                toId,
                currencyFromId,
                currencyToId,
                amount: +amount,
                partner,
                fixed,
                rateId: selectedPartnerRateId,
              }
            : {
                fromId: fromId,
                toId: toId,
                currencyFromId: currencyFromId,
                currencyToId: currencyToId,
                amount: exchangeFields[0].value,
                sourceChain: exchangeFields[0].chainSymbol,
                destinationChain: exchangeFields[1].chainSymbol,
                fromToken: exchangeFields[0].name,
                toToken: exchangeFields[1].name,
              }
        }
        resetTwoFa={swapResetTwoFa}
      />
      <Modal
        visible={showConfirmModal}
        footer={null}
        className="swap-confirm-modal"
        onCancel={() => setShowConfirmModal(false)}
        centered={true}
        closable={false}
        maskClosable={false}
      >
        <div className="swap-confirm-modal__main">
          <div className="swap-confirm-modal__title">{t('swap.confirm.title')}</div>
          <div className="swap-confirm-modal__body">
            {swapFetching || makeStableSwapFetching ? (
              <>
                <div className="swap-confirm-modal__loader">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                  <div className="swap-confirm-modal__message">{t('swap.loading')}</div>
                </div>
              </>
            ) : swapError || makeStableSwapError ? (
              <>
                <div className="swap-confirm-modal__icon">
                  <SwapTickFailIcon />
                </div>
                <div className="swap-confirm-modal__status">{t('swap.confirm.wrong')}</div>
                <div className="swap-confirm-modal__message">
                  {swapError ? (
                    <>
                      {translateError(t, swapError).map(item => (
                        <p
                          className={
                            swapError.includes('Miner Fee')
                              ? `swap-confirm-modal__error-miner swap-confirm-modal__error-miner-${identity}`
                              : `swap-confirm-modal__error-maximum swap-confirm-modal__error-maximum-${identity}`
                          }
                        >
                          {item}
                        </p>
                      ))}
                    </>
                  ) : (
                    <>
                      {translateError(t, makeStableSwapError).map(item => (
                        <p
                          className={
                            makeStableSwapError.includes('Miner Fee')
                              ? `swap-confirm-modal__error-miner swap-confirm-modal__error-miner-${identity}`
                              : `swap-confirm-modal__error-maximum swap-confirm-modal__error-maximum-${identity}`
                          }
                        >
                          {item}
                        </p>
                      ))}
                    </>
                  )}
                </div>
                <div className="swap-confirm-modal__fail-buttons">
                  <Button
                    type="secondary"
                    onClick={() => {
                      handleErrorMsgClick();
                    }}
                  >
                    {t('swap.confirm.backToStepOne')}
                  </Button>
                  <Button
                    onClick={() => {
                      swapOffersReset();
                      swapReceiveReset();
                      resetExchangeError();
                      resetStableSwapError();
                      history.push('/swap');
                    }}
                  >
                    {t('swap.confirm.backToHistory')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="swap-confirm-modal__icon">
                  <SwapTickSuccessIcon />
                </div>
                <div className="swap-confirm-modal__status">{t('swap.confirm.swapSuccess')}</div>
                <div className="swap-confirm-modal__message">{t('swap.confirm.transaction')}</div>
                <div className="swap-confirm-modal__success-buttons">
                  <Button
                    onClick={() => {
                      swapOffersReset();
                      swapReceiveReset();
                      history.push('/swap');
                    }}
                  >
                    {t('swap.confirm.backToHistory')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      {!isSwapStable ? (
        <>
          <div className="swap-confirm__wrapper">
            <SwapNewCard title={t('swap.summary')}>
              {showUpdateRatesBanner && (
                <div className={`swap-confirm-banner swap-confirm-banner-${identity}`}>
                  <div className="swap-confirm-banner__left">
                    <ChartIcon className="swap-confirm-banner__icon" />
                    <div>
                      <div className="swap-confirm-banner__title">{t('swap.confirm.exchangeRate')}</div>
                      <div className="swap-confirm-banner__subtitle">{t('swap.confirm.continue')}</div>
                    </div>
                  </div>
                  <div className="swap-confirm-banner__right">
                    <Button
                      className="swap-confirm-banner__button"
                      loading={swapOffersUpdating}
                      disabled={swapOffersUpdating}
                      onClick={() => {
                        setShowUpdateRatesBanner(false);
                        swapOffersUpdateApply();
                      }}
                    >
                      {t('swap.confirm.updateRate')}
                    </Button>
                  </div>
                </div>
              )}
              <div className="swap-confirm">
                <div className="swap-confirm__value">
                  <div className="swap-confirm__send">
                    <div className="swap-confirm__value-title">{t('swap.confirm.youSend')}</div>
                    <div className="swap-confirm__value-wrap">
                      {currencyFromName && (
                        <div className="swap-confirm__value-icon">
                          {currencyFromName && <TokenIcon tokenName={currencyFromName.name} />}
                        </div>
                      )}
                      <div>
                        <span className="swap-confirm__value-amount">{amount}</span>
                        <span className="swap-confirm__value-currency">{currencyFromName.title}</span>
                      </div>
                    </div>
                  </div>
                  <ArrowRightIcon className="swap-confirm__value-arrow" />
                  <div className="swap-confirm__get">
                    <div className="swap-confirm__value-title">{t('swap.confirm.youGet')}</div>
                    <div className="swap-confirm__value-wrap">
                      {currencyToName && (
                        <div className="swap-confirm__value-icon">
                          {currencyToName && <TokenIcon tokenName={currencyToName.name} />}
                        </div>
                      )}
                      <div>
                        <span className="swap-confirm__value-amount">
                          {getDecimalValue(summaryRate.toString(), Number(currencyToName?.decimals))}
                        </span>
                        <span className="swap-confirm__value-currency">{currencyToName.title}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swap-confirm__rate">
                  <div className="swap-confirm__rate-title">{t('swap.confirm.exchange')}:</div>
                  <div className="swap-confirm__rate-flex">
                    <div className="swap-confirm__rate-info">
                      <div className="swap-confirm__rate-icon">
                        {currencyFromName && <TokenIcon tokenName={currencyFromName.name} />}
                      </div>
                      <div>
                        <span className="swap-confirm__rate-amount">1</span>
                        <span className="swap-confirm__rate-currency">{currencyFromName.title}</span>
                      </div>
                    </div>
                    <span className="swap-confirm__rate-divider">-</span>
                    <div className="swap-confirm__rate-info">
                      <div className="swap-confirm__rate-icon">
                        {currencyToName && <TokenIcon tokenName={currencyToName.name} />}
                      </div>
                      <div>
                        <span className="swap-confirm__rate-amount">
                          {getDecimalValue((summaryRate / amount).toString(), Number(currencyToName?.decimals))}
                        </span>
                        <span className="swap-confirm__rate-currency">{currencyToName.title}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="swap-confirm__partner-info">
                  <div className="swap-confirm__partner-info-name">
                    <div className="swap-confirm__partner-info-title">{t('swap.offerItem.partner')}:</div>
                    <div className="swap-confirm__partner-info-data">
                      <img src={getSwapPartnerIcon(partner)} alt={partner} /> {partner}
                    </div>
                  </div>
                  <div className="swap-confirm__partner-info-type">
                    <div className="swap-confirm__partner-info-title">{t('swap.offerItem.type')}:</div>
                    {fixed && selectedPartnerRateId ? (
                      <div className="swap-confirm__partner-info-data">
                        <FixedIcon /> {t('swap.offerItem.fixed')}
                      </div>
                    ) : (
                      <div className="swap-confirm__partner-info-data">
                        <FloatingIcon /> {t('swap.offerItem.floating')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </SwapNewCard>
            <div className="swap-wallets__button">
              <Button
                disabled={showUpdateRatesBanner}
                onClick={() => {
                  // setShowConfirmModal(true);
                  makeSwap({
                    fromId,
                    toId,
                    currencyFromId,
                    currencyToId,
                    amount: +amount,
                    partner,
                    fixed,
                    rateId: selectedPartnerRateId,
                  }).then(response => {
                    if (response && response.data) {
                      if (response.data.twoFactorToken || response.data.emailSent || response.data.codeSent) {
                        setShowTwoFaModal(true);
                      }
                    }
                  });
                }}
              >
                {t('swap.swap')}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="swap-confirm__wrapper">
          <SwapNewCard title={t('swap.summary')}>
            {/* {showUpdateRatesBanner && (
              <div className="swap-confirm-banner">
                fsfsfsfsfsffs
                <div className="swap-confirm-banner__left">
                  <ChartIcon className="swap-confirm-banner__icon" />
                  <div>
                    <div className="swap-confirm-banner__title">{t('swap.confirm.exchangeRate')}</div>
                    <div className="swap-confirm-banner__subtitle">{t('swap.confirm.continue')}</div>
                  </div>
                </div>
                <div className="swap-confirm-banner__right">
                  <Button
                    className="swap-confirm-banner__button"
                    loading={swapOffersUpdating}
                    disabled={swapOffersUpdating}
                    onClick={() => {
                      setShowUpdateRatesBanner(false);
                      swapOffersUpdateApply();
                    }}
                  >
                    {t('swap.confirm.updateRate')}
                  </Button>
                </div>
              </div>
            )} */}
            <div className="swap-confirm">
              <div className="swap-confirm__value">
                <div className="swap-confirm__send">
                  <div className="swap-confirm__value-title">{t('swap.confirm.youSend')}</div>
                  <div className="swap-confirm__value-wrap">
                    {currencyFromName && (
                      <div className="swap-confirm__value-icon">
                        {currencyFromName && <TokenIcon tokenName={currencyFromName.name} />}
                      </div>
                    )}
                    <div>
                      <span className="swap-confirm__value-amount">{amount}</span>
                      <span className="swap-confirm__value-currency">{currencyFromName.title}</span>
                    </div>
                  </div>
                </div>
                <ArrowRightIcon className="swap-confirm__value-arrow" />
                <div className="swap-confirm__get">
                  <div className="swap-confirm__value-title">{t('swap.confirm.youGet')}</div>
                  <div className="swap-confirm__value-wrap">
                    {currencyToName && (
                      <div className="swap-confirm__value-icon">
                        {currencyToName && <TokenIcon tokenName={currencyToName.name} />}
                      </div>
                    )}
                    <div>
                      <span className="swap-confirm__value-amount">
                        {getDecimalValue(
                          priceForSwap?.amountToBeReceived?.toString(),
                          Number(currencyToName?.decimals)
                        )}
                      </span>
                      <span className="swap-confirm__value-currency">{currencyToName.title}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swap-confirm__rate">
                <div className="swap-confirm__rate-title">{t('swap.confirm.exchange')}</div>
                <div className="swap-confirm__rate-flex">
                  <div className="swap-confirm__rate-info">
                    <div className="swap-confirm__rate-icon">
                      {currencyFromName && <TokenIcon tokenName={currencyFromName.name} />}
                    </div>
                    <div>
                      <span className="swap-confirm__rate-amount">1</span>
                      <span className="swap-confirm__rate-currency">{currencyFromName.title}</span>
                    </div>
                  </div>
                  <span className="swap-confirm__rate-divider">-</span>
                  <div className="swap-confirm__rate-info">
                    <div className="swap-confirm__rate-icon">
                      {currencyToName && <TokenIcon tokenName={currencyToName.name} />}
                    </div>
                    <div>
                      <span className="swap-confirm__rate-amount">
                        {getDecimalValue(
                          ((priceForSwap?.amountToSend * 1) / priceForSwap?.amountToBeReceived).toString(),
                          Number(currencyToName?.decimals)
                        )}
                      </span>
                      <span className="swap-confirm__rate-currency">{currencyToName.title}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwapNewCard>
          <div className="swap-wallets__button">
            <Button
              // disabled={showUpdateRatesBanner}
              onClick={() => {
                // setShowConfirmModal(true);
                makeStableSwap({
                  fromId: fromId,
                  toId: toId,
                  currencyFromId: currencyFromId,
                  currencyToId: currencyToId,
                  amount: exchangeFields[0].value,
                  sourceChain: exchangeFields[0].chainSymbol,
                  destinationChain: exchangeFields[1].chainSymbol,
                  fromToken: exchangeFields[0].name,
                  toToken: exchangeFields[1].name,
                }).then(response => {
                  if (response && response.data) {
                    if (response.data.twoFactorToken || response.data.emailSent || response.data.codeSent) {
                      setShowTwoFaModal(true);
                    }
                  }
                });
              }}
            >
              {t('swap.swap')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  currencies: state.currencies.data,
  swapData: state.swap.exchange.data,
  swapError: state.swap.exchange.error,
  swapFetching: state.swap.exchange.fetching,
  makeStableSwapData: state.swap.makeStable.data,
  makeStableSwapError: state.swap.makeStable.error,
  makeStableSwapFetching: state.swap.makeStable.fetching,
});

const mapDispatchToProps = dispatch => ({
  makeSwap: makeSwap(dispatch),
  makeStableSwap: makeStableSwap(dispatch),
  swapResetTwoFa: () => dispatch(swapResetTwoFa()),
  resetStableSwapError: () => dispatch(resetStableSwapError()),
  resetExchangeError: () => dispatch(resetExchangeError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwapConfirm);
