import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../Modal';
import TEXT from '../Text';
import moment from 'moment';
import { ButtonPrimary } from '../Button';
import { getAdminUserById, resetAdminUserState } from '../../../redux/actions/adminPanel/adminUsers';
import { ReactComponent as EditAdminSVG } from '../../../img/default-svg/editAdmin.svg';
import './style.scss';

const UserInfoModal = ({
  selectedUserId,
  setSelectedUserId,
  userData,
  userDateFetching,
  getAdminUserById,
  resetAdminUserState,
}) => {
  useEffect(() => {
    if (selectedUserId) {
      getAdminUserById(selectedUserId);
    }
  }, [selectedUserId]);

  const handleClose = () => {
    setSelectedUserId(null);
    resetAdminUserState();
  };

  return (
    <Modal
      title="Profile"
      visible={!!selectedUserId}
      width={472}
      footerComponent={
        <Link to={`/admin/user-edit/${userData.id}`}>
          <ButtonPrimary className="user-info-modal__edit-button">
            <EditAdminSVG />
            Edit
          </ButtonPrimary>
        </Link>
      }
      onCancel={handleClose}
    >
      <div className="user-info-modal">
        <div className="user-info-modal__title">
          <TEXT.darkBig>
            <div
              className={`user-info-modal__color-status ${
                userData.isActive ? 'user-info-modal__color-status_active' : 'user-info-modal__color-status_inactive'
              }`}
            ></div>
            {userData.name} {userData.surname}
          </TEXT.darkBig>
        </div>
        <div className="user-info-modal__row">
          <TEXT.grayBold>Date of registration:</TEXT.grayBold>
          <TEXT.darkBold>{moment(userData.createdAt).format('MMMM D, YYYY, HH:mm;')}</TEXT.darkBold>
        </div>
        {/* <div className="user-info-modal__row">
          <TEXT.grayBold>Date of last login:</TEXT.grayBold>
          <TEXT.darkBold>
            {userData.lastLogin ? moment(userData.lastLogin).format('MMMM D, YYYY, HH:mm;') : '-'}
          </TEXT.darkBold>
        </div> */}
        <div className="user-info-modal__row">
          <TEXT.grayBold>Email:</TEXT.grayBold>
          <div>
            <TEXT.darkBold>{userData.email || '-'}</TEXT.darkBold>
            <TEXT.grayBoldSmall>{userData.emailConfirmed ? 'Confirmed' : 'Not confirmed'}</TEXT.grayBoldSmall>
          </div>
        </div>
        <div className="user-info-modal__row">
          <TEXT.grayBold>Telephone number:</TEXT.grayBold>
          <TEXT.darkBold>{userData.phone || '-'}</TEXT.darkBold>
        </div>
        <div className="user-info-modal__row">
          <TEXT.grayBold>Metamask address:</TEXT.grayBold>
          <TEXT.darkBold>{userData.metamask?.address || '-'}</TEXT.darkBold>
        </div>
        <div className="user-info-modal__row">
          <TEXT.grayBold>Role:</TEXT.grayBold>
          <TEXT.darkBold>{userData.role || '-'}</TEXT.darkBold>
        </div>
        <div className="user-info-modal__row">
          <TEXT.grayBold>ID:</TEXT.grayBold>
          <TEXT.darkBold>{userData.id || '-'}</TEXT.darkBold>
        </div>
        {/* <div className="user-info-modal__row">
          <TEXT.grayBold>Ref ID:</TEXT.grayBold>
          <TEXT.darkBold>{userData.refId || '-'}</TEXT.darkBold>
        </div> */}
        <div className="user-info-modal__row">
          <TEXT.grayBold>2FA:</TEXT.grayBold>
          <TEXT.darkBold>{userData.totpTwoFaStatus ? 'Enabled' : 'Disabled'}</TEXT.darkBold>
        </div>
        <div className="user-info-modal__row">
          <TEXT.grayBold>Backup Phrase: </TEXT.grayBold>
          <TEXT.darkBold>{userData.backupPhrase ? 'Enabled' : 'Disabled'}</TEXT.darkBold>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  userData: state.adminUsers.editableUser.data,
  userDateFetching: state.adminUsers.editableUser.fetching 
});

const mapDispatchToProps = {
  getAdminUserById,
  resetAdminUserState,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoModal);
