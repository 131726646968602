import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import menu from '../../img/default-svg/menu.svg';
import logoCpay from '../../img/footer/footerLogo.svg';
import logoNfg from '../../img/nfgpay-svg/nfg_logo_white.svg';
import HeaderUser from '../components/HeaderUser/HeaderUser';
import NetworkFilter from '../../components/NetworkFilter';
import './Header.scss';
import { AppConfig } from '../../config';
import { identity } from '../../utils/getIdentity';

const logos = {
  cpay: logoCpay,
  nfg: logoNfg,
};

function Header({ toggleCollapseSider }) {
  return (
    <div className={`admin-header admin-header-${identity}`}>
      <Row align="middle" gutter={[32, 0]}>
        <Col flex="90px">
          <span className="admin-header__toggle-sider" onClick={toggleCollapseSider}>
            <img src={menu} alt="menu" />
          </span>
        </Col>
        <Col className="admin-header__logo">
          <Link to="/admin">
            <img src={logos[AppConfig.identity]} alt="logo" />
          </Link>
        </Col>
        <Col flex="auto">
          <div className="admin-header__network-filter-wrap">
            <NetworkFilter />
          </div>
        </Col>
        <Col className="admin-header__user">
          <div className="admin-header__controls-wrapper">
            <HeaderUser />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Header;
