import React, { useState, useEffect, useMemo } from 'react';
import WalletSelect from './WalletSelect/WalletSelect';
import { getNftWallets } from '../../../redux/actions/nft';
import { connect } from 'react-redux';
import { Empty, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Button from '../../Button';
import NftWithdraw from '../NftWithdraw';
import TokenIcon from '../../TokenIcon';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';
import { identity } from '../../../utils/getIdentity';

const NftsWrapper = ({
  merchantId,
  typeNetwork,
  nftWallets,
  getNftWallets,
  nftWalletsFetching,
  setShowTransactionsModal,
}) => {
  const { t } = useTranslation();

  const [selectedWallets, setSelectedWallets] = useState([]);
  const [nftItems, setNftItems] = useState([]);
  const [selectedNft, setSelectedNft] = useState(null);

  useEffect(() => {
    if (merchantId) {
      getNftWallets(merchantId, {
        typeNetwork,
        limit: -1,
        order: 'DESC',
        showNfts: true,
        onlyNft: true,
      });

      setSelectedWallets([])
    }
  }, [merchantId, typeNetwork]);

  useEffect(() => {
    setNftItems(
      nftWallets.reduce((result, wallet) => {
        if (!selectedWallets.length || selectedWallets.includes(wallet._id)) {
          result.push(
            ...wallet.nfts.erc721.map(nft => ({
              ...nft,
              walletId: wallet._id,
              network: 'erc721',
              currency: wallet.currency,
            })),
            ...wallet.nfts.erc1155.map(nft => ({
              ...nft,
              walletId: wallet._id,
              network: 'erc1155',
              currency: wallet.currency,
            }))
          );
        }

        return result;
      }, [])
    );
  }, [nftWallets, selectedWallets]);

  const nftData = useMemo(() => {
   const nfts = nftItems.length ? (
   <div className={styles['nfts-list']}>
    {nftItems.map((nft, index) => {
      return (
        <div className={styles['nft-item']} key={crypto.randomUUID()}>
          {nft.imageURL ? (
            <img className={styles['nft-item__image']} src={nft.imageURL} alt={nft.name} />
          ) : (
            <Empty className={styles['nft-item__empty']} description="" />
          )}
          <div className={styles['nft-item__info']}>
              <div className={styles['nft-item__name']}>
                {nft.name}
              </div>

              <div className={styles['nft-item__token-info']}>
            <div  className={styles['nft-item__token-data']}>
            <div className={styles['nft-item__token']}>
                            <TokenIcon className={styles['nft-item__currency-icon']} tokenName={nft.currency} />
                            {nft.network}
                          </div>
                          <div className={styles['nft-item__balance']}>{t('balance')}: {nft.balance}</div>

            </div>

            <div className={`${styles['nft-item__button']} ${styles[`nft-item__button-${identity}`]}`} onClick={() => setSelectedNft(nft)}>
              {t('transfer')}
            </div>
              </div>

            </div>
        </div>
      );
    })}
  </div>
   ) : (
  <Empty
    className={styles['nfts-list__empty']}
    image={
      nftWalletsFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : undefined
    }
    description={nftWalletsFetching ? '' : undefined}
  />
  )

return nfts
  }, [nftItems, nftWalletsFetching, t])

  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        <div>
          <div className={styles['title']}>{t('nft.nftCollection')}</div>
          <span className={styles['subtitle']}>{t('nft.nftSupported')} ETH, BSC, Polygon, Optimism {t('and')} Solana networks</span>
        </div>
        <div className={styles['header__controls']}>
          <WalletSelect
            nftWallets={nftWallets}
            selectedWallets={selectedWallets}
            setSelectedWallets={setSelectedWallets}
          />
          <Button className={styles['transactions-button']} onClick={() => setShowTransactionsModal(true)}>
            {t('nft.transactionHistory')}
          </Button>
        </div>
      </div>

      <NftWithdraw merchantId={merchantId} selectedNft={selectedNft} setSelectedNft={setSelectedNft} />

      {nftData}
    </div>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  typeNetwork: state.networkFilter,
  nftWallets: state.nft.wallets.data,
  nftWalletsFetching: state.nft.wallets.fetching,
});

const mapDispatchToProps = dispatch => ({
  getNftWallets: getNftWallets(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NftsWrapper);
