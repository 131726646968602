import React from 'react';
import { Modal } from 'antd';
import { ReactComponent as CloseSVG } from '../../../img/default-svg/close-black.svg';
import SelectType from './Steps/SelectType';
import CustomerInfo from './Steps/CustomerInfo';
import SetData from './Steps/SetData';
import DetailedCheckout from './Steps/DetailedCheckout';
import { useTranslation } from 'react-i18next';
import './style.scss';

const CheckoutsModals = ({
  visible,
  close,
  isDuplicating,
  checkoutCustomerInfo,
  setCheckoutCustomerInfo,
  setCheckoutType,
  checkoutType,
  createCheckoutHandler,
  nextStep,
  prevStep,
  step,
  editStep,
  setEditStep,
  detailedCheckoutState,
  editCheckoutState,
  editCheckoutHandler,
  ifCreateFetching,
  ifPatchFetching,
  activeMerchantCurrencies,
  merchantId,
  merchants
}) => {
  const { t } = useTranslation();

  const Steps = {
    0: {
      title: t(`checkouts.steps.${[step]}.title`),
      component: <SelectType setCheckoutType={setCheckoutType} nextStep={nextStep} />,
    },
    1: {
      title: t('checkouts.title'),
      component: (
        <CustomerInfo
          editCheckoutState={editCheckoutState}
          checkoutCustomerInfo={checkoutCustomerInfo}
          setCheckoutCustomerInfo={setCheckoutCustomerInfo}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      ),
    },
    2: {
      title: t(`checkouts.steps.${[step]}.${[checkoutType]}.title`),
      component: (
        <SetData
          prevStep={prevStep}
          checkoutType={checkoutType}
          createCheckoutHandler={createCheckoutHandler}
          ifCreateFetching={ifCreateFetching}
          ifPatchFetching={ifPatchFetching}
          activeMerchantCurrencies={activeMerchantCurrencies}
        />
      ),
    },
  };

  return (
    <Modal
      width={620}
      title=""
      visible={visible}
      onCancel={close}
      footer={null}
      className="checkout-modal"
      centered
      closeIcon={<CloseSVG />}
      destroyOnClose
      maskClosable={false}
    >
      <div className="checkout-modal-content">
        {detailedCheckoutState ? (
          <>
            <div className="checkout-modal-content__title">
              {detailedCheckoutState.type === 'Donation'
                ? t('checkouts.steps.2.donation.title')
                : t('checkouts.steps.2.sale.title')}
            </div>
            <DetailedCheckout
              detailedCheckoutState={detailedCheckoutState}
              close={close}
              merchantId={merchantId}
              merchants={merchants}
            />
          </>
        ) : editCheckoutState ? (
          <>
            <div className="checkout-modal-content__title">
              {editCheckoutState.type === 'Donation'
                ? t('checkouts.steps.2.donation.title')
                : t('checkouts.steps.2.sale.title')}
            </div>
            {editStep === 0 ? (
              <CustomerInfo
                editCheckoutState={editCheckoutState}
                checkoutCustomerInfo={checkoutCustomerInfo}
                setCheckoutCustomerInfo={setCheckoutCustomerInfo}
                nextStep={() => setEditStep(1)}
              />
            ) : (
              <SetData
                isDuplicating={isDuplicating}
                prevStep={() => setEditStep(0)}
                createCheckoutHandler={isDuplicating ? createCheckoutHandler : editCheckoutHandler}
                editCheckoutState={editCheckoutState}
                ifCreateFetching={ifCreateFetching}
                ifPatchFetching={ifPatchFetching}
                activeMerchantCurrencies={activeMerchantCurrencies}
              />
            )}
          </>
        ) : (
          <>
            <div className="checkout-modal-content__title">{Steps[step].title}</div>
            {Steps[step].component}
          </>
        )}
      </div>
    </Modal>
  );
};

export default CheckoutsModals;
