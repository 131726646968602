import React from 'react';
// Components
import Button from '../../Button';
import { Input } from 'antd';
import resetCpayIcon from '../../../img/cpay-svg/reset.svg';
import resetNfgIcon from '../../../img/nfgpay-svg/reset-nfg.svg'
// Utils
import { useTranslation } from 'react-i18next';
// Style
import './style.scss';
import { identity } from '../../../utils/getIdentity';

const resetIcon = {
  cpay: resetCpayIcon,
  nfg: resetNfgIcon
}

const CheckoutsHeader = ({ data, setIsVisible, fetching, search, setSearch, getCheckoutListHandler }) => {
  const { t } = useTranslation();

  const resetSearch = () => {
    setSearch(null);
    getCheckoutListHandler(1);
  };

  return (
    <div className="checkouts-header">
      <div className="checkouts-header__title">
        {t('checkouts.checkouts')}
        <div className="checkouts-header__controls">
          <Input.Search
            disabled={fetching}
            name="search"
            placeholder={t('search')}
            onSearch={() => getCheckoutListHandler(1, search)}
            className="checkouts-header__search-field"
            onChange={e => setSearch(e.target.value)}
            value={search}
            addonAfter={
              <span onClick={resetSearch} className="checkouts-header__reset-button">
                <img src={resetIcon[identity]} alt="Reset" />
              </span>
            }
          />
          {data && data.length ? (
            <Button type="secondary" className="checkouts-list__button" onClick={() => setIsVisible(true)}>
              {t('checkouts.createCheckout')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CheckoutsHeader;
