import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../img/cpay-svg/arrow-back.svg';
import { ReactComponent as ArrowIconNFG } from '../../img/nfgpay-svg/arrow-back-nfg.svg';
import SignupForm from '../SignupForm/SignupForm';
import SignupPhoneForm from '../SignupPhoneForm';
import LoginSocialMedia from '../LoginSocialMedia';
import RolesSelector from '../RolesSelector';
import './style.scss';
import { identity } from '../../utils/getIdentity';

const copyIcon = {
  cpay: <ArrowIcon />,
  nfg: <ArrowIconNFG />,
};

const SignUpOptions = ({ toggleWelcomeBlock, setAccountType }) => {
  const query = useQuery();
  const [loginOption, setLoginOption] = useState(query.get('email') || query.get('status') ? 1 : 0);
  const { t } = useTranslation();
  const [userType, setUserType] = useState('corporate');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  return (
    <>
      {loginOption === 0 ? (
        <div className="login-form sign-up-options">
          <span className="sign-up-options__title">{t('auth.createAnAccount')}</span>
          <div className="sign-up-options__items">
            <div className="sign-up-options__item" onClick={() => setLoginOption(1)}>
              <span>{t('auth.signUpWithEmail')}</span>
              {copyIcon[identity]}
            </div>
            <div className="sign-up-options__item" onClick={() => setLoginOption(2)}>
              <span>{t('auth.signUpWithPhone')}</span>
              {copyIcon[identity]}
            </div>
            <div className="sign-up-options__divider">Or</div>

            <LoginSocialMedia userType={userType} />
          </div>
          <div className={`login-form__text login-form__text_center login-form__text-${identity}`}>
            {t('auth.alreadyHaveAccount')} <Link to="/login">{t('logIn')}!</Link>
          </div>
        </div>
      ) : loginOption === 1 ? (
        <SignupForm
          toggleWelcomeBlock={toggleWelcomeBlock}
          setAccountType={setAccountType}
          backAction={() => setLoginOption(0)}
        />
      ) : (
        <SignupPhoneForm
          toggleWelcomeBlock={toggleWelcomeBlock}
          setAccountType={setAccountType}
          backAction={() => setLoginOption(0)}
        />
      )}
    </>
  );
};

export default SignUpOptions;
