import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '../../img/default-svg/copy.svg';
import { ReactComponent as CopyIconNfg } from '../../img/nfgpay-svg/copy-blue-nfg.svg';
import './style.scss'
import { identity } from '../../utils/getIdentity';

const copyIcon = {
  cpay: <CopyIcon/>,
  nfg: <CopyIconNfg/>
}

const CheckoutsLink = () => {
  const {t} = useTranslation();

  return (
    <div className="checkouts-link">
      <div>
        <span className="checkouts-link__title">{t('checkoutsLink.title')}</span>
        <span className="checkouts-link__description">
          {t('checkoutsLink.description')}
        </span>
      </div>
      <Link to="/checkouts" className={`checkouts-link__button checkouts-link__button-${identity}`}>
      {t('checkoutsLink.link')} {copyIcon[identity]}
      </Link>
    </div>
  );
};

export default CheckoutsLink;
