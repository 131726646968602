import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import abi from 'human-standard-token-abi';
import Button from '../../Button';
import { notificationsErrorShow } from '../../../redux/actions/notifications';
import { ReactComponent as LogoutIcon } from '../../../img/default-svg/logout.svg';
import { ReactComponent as InfoCircleCpay } from '../../../img/default-svg/expired.svg';
import { ReactComponent as InfoCircleNfg } from '../../../img/nfgpay-svg/info-circle-nfg.svg';

import './style.scss';
import { identity } from '../../../utils/getIdentity';

const infoCircle = {
  cpay: <InfoCircleCpay/>,
  nfg: <InfoCircleNfg/>
}

const chainIds = {
  mainnet: {
    eth: 1,
    bsc: 56,
  },
  testnet: {
    eth: 11155111,
    bsc: 97,
  },
};

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        1: 'https://cloudflare-eth.com/',
        11155111: 'https://rpc.sepolia.org',
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        56: 'https://bsc-dataseed1.binance.org/',
      },
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
});

const WalletConnectButton = ({
  clientWalletAddress,
  amount,
  currency,
  notificationsErrorShow,
  typeNetwork,
  setShowAmountRequired,
}) => {
  const [web3state, setWeb3state] = useState({});
  const { provider, web3Provider, address, chainId } = web3state;
  const { t } = useTranslation();

  const connect = useCallback(async function () {
    const provider = await web3Modal.connect();
    const web3Provider = new Web3(provider);
    const address = await web3Provider.eth.getAccounts();
    const chainId = await web3Provider.eth.getChainId();

    setWeb3state({
      provider,
      web3Provider,
      address: address[0],
      chainId,
    });
  }, []);

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider && provider.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect();
      }
      setWeb3state({});
    },
    [provider]
  );

  const toWei = (amount, decimals) => {
    const split = `${amount}`.split('.');
    split[1] = split[1] ? split[1].padEnd(decimals, 0) : ''.padEnd(decimals, 0);
    return split.join('');
  };

  const handleSend = async () => {
    let gasLimit = await web3Provider.eth.estimateGas({
      to: clientWalletAddress,
      chainId: web3Provider.utils.toHex(parseInt(chainId)),
    });

    gasLimit = await web3Provider.utils.toHex(gasLimit);

    let gasPrice = await web3Provider.eth.getGasPrice();

    gasPrice = await web3Provider.utils.toHex(gasPrice);

    if (currency.currencyType === 'currency') {
      try {
          await web3Provider.eth.sendTransaction({
          from: address,
          to: clientWalletAddress,
          value: web3Provider.utils.toWei(amount, 'ether'),
          gasLimit,
          gasPrice,
        });
      } catch (error) {
        notificationsErrorShow({
          error: 'Transaction declined',
          message:
            error.message === 'Internal JSON-RPC error.'
              ? `${error.message} Check if you have enough tokens for transfer and commission`
              : error.message,
        });
      }
    } else {
      const tokenInst = new web3Provider.eth.Contract(abi, currency.contractAddress);

      tokenInst.methods.transfer(clientWalletAddress, toWei(amount, currency.decimals)).send(
        {
          from: address,
          // gasLimit,
          gasPrice,
        },
        function (error, result) {
          if (!error) {
          } else {
            notificationsErrorShow({
              error: 'Transaction declined',
              message:
                error.message === 'Internal JSON-RPC error.'
                  ? `${error.message} Check if you have enough tokens for transfer and commission`
                  : error.message,
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = accounts => {
        setWeb3state({
          ...web3state,
          address: accounts[0],
        });
      };

      const handleChainChanged = chainId => {
        setWeb3state({
          ...web3state,
          chainId,
        });
      };

      const handleDisconnect = error => {
        console.log('disconnect', error);
        notificationsErrorShow({ message: 'Wallet disconnected' });
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider, disconnect, web3state]);

  const truncate = (fullStr = '', strLen, separator = '...') => {
    if (fullStr.length <= strLen) return fullStr;

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars);
  };

  return (
    <div className="wallet-connect">
      {address && chainId !== chainIds[typeNetwork][currency.nodeType] && (
        <div className="wallet-connect__warning">
          {infoCircle[identity]}
          {typeNetwork === 'mainnet'
            ? `${t('checkouts.connectWallet.wrongNetwork')} ${
                chainIds[typeNetwork][currency.nodeType] === 1
                  ? 'Ethereum Mainnet'
                  : chainIds[typeNetwork][currency.nodeType] === 56
                  ? 'Binance Smart Chain Mainnet'
                  : 'suited mainnet'
              } network`
            : `${t('checkouts.connectWallet.wrongNetwork')} ${
                chainIds[typeNetwork][currency.nodeType] === 11155111
                  ? 'Sepolia Testnet'
                  : chainIds[typeNetwork][currency.nodeType] === 97
                  ? 'Binance Smart Chain Testnet'
                  : 'suited testnet'
              } network`}
        </div>
      )}

      {web3Provider ? (
        <>
          <div className="wallet-connect__account">
            <div className="wallet-connect__address">{truncate(address, 14)}</div>
            <LogoutIcon onClick={disconnect} />
          </div>
          <Button
            className="wallet-connect__button wallet-connect__button-send"
            onClick={() => (amount ? handleSend() : setShowAmountRequired(true))}
            disabled={address && chainId !== chainIds[typeNetwork][currency.nodeType]}
          >
            {t('send')}
          </Button>
        </>
      ) : (
        <Button className="wallet-connect__button" onClick={connect}>
          {t('checkouts.connectWallet.connectButton')}
        </Button>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(null, mapDispatchToProps)(WalletConnectButton);
