import React from 'react';
import { Row, Col } from 'antd';
import Button from '../Button';
import '../../layout/Modal.scss';
import { useTranslation } from 'react-i18next';
import { identity } from '../../utils/getIdentity';

const WalletModalType = ({ setWalletCreateMode }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="modal__text">
        <span>{t('wallets.existOrNewWallet')}</span>
      </div>
      <Row className="modal__buttons-wrapper">
        <Col>
          <Button
            type="primary"
            className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
            onClick={() => setWalletCreateMode('new')}
          >
            {t('wallets.new')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
            onClick={() => setWalletCreateMode('existing')}
          >
            {t('wallets.existing')}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WalletModalType;
