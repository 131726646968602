import {
  WITHDRAW_WALLETS_GET,
  WITHDRAW_WALLETS_SET,
  WITHDRAW_SET_APP_TWO_FA,
  WITHDRAW_SET_EMAIL_TWO_FA,
  WITHDRAW_SET_PHONE_TWO_FA,
  WITHDRAW_RESET_TWO_FA,
  WITHDRAW_CREATE_TRANSACTION,
  WITHDRAW_ERROR,
  WITHDRAW_ESTIMATE_MAX_REQUEST,
  WITHDRAW_ESTIMATE_MAX_SUCCESS,
  WITHDRAW_ESTIMATE_MAX_ERROR,
  WITHDRAW_ESTIMATE_MAX_RESET,
  CHECK_AUTOSIGN_STATUS_REQUEST,
  CHECK_AUTOSIGN_STATUS_SUCCESS,
  CHECK_AUTOSIGN_STATUS_ERROR,
  UPLOAD_NEW_AUTOSIGN_SUCCESS,
  RESET_AUTOSIGN_STEP
} from '../actions/withdraw';

export default function reducer(
  state = {
    wallets: { data: [], fetching: false },
    maxAmount: null,
    status: null,
    isAppTwoFa: false,
    isEmailTwoFa: false,
    isPhoneTwoFa: false,
    fetching: false,
    fetchingMaxAmount: false,
    autosignStatus: true,
    error: null,
    autosignError: null,
    autosignStep: 0,
  },
  action
) {
  switch (action.type) {
    case WITHDRAW_WALLETS_GET:
      return {
        ...state,
        wallets: { ...state.wallets, fetching: true },
      };
    case WITHDRAW_WALLETS_SET:
      return {
        ...state,
        wallets: { ...state.wallets, data: action.payload, fetching: false },
      };
    case WITHDRAW_SET_APP_TWO_FA:
      return {
        ...state,
        isAppTwoFa: action.isAppTwoFa,
        fetching: false,
      };
    case WITHDRAW_SET_EMAIL_TWO_FA:
      return {
        ...state,
        isEmailTwoFa: action.isEmailTwoFa,
        fetching: false,
      };
    case WITHDRAW_SET_PHONE_TWO_FA:
      return {
        ...state,
        isPhoneTwoFa: action.isPhoneTwoFa,
        fetching: false,
      };
    case WITHDRAW_RESET_TWO_FA:
      return {
        ...state,
        isAppTwoFa: false,
        isEmailTwoFa: false,
        isPhoneTwoFa: false,
        fetching: false,
      };
    case WITHDRAW_CREATE_TRANSACTION:
      return {
        ...state,
        fetching: true,
      };
    case WITHDRAW_ERROR:
      return {
        ...state,
        fetching: false,
      };

    case WITHDRAW_ESTIMATE_MAX_REQUEST:
      return {
        ...state,
        fetchingMaxAmount: true,
        maxAmount: null,
        error: null
      };

    case WITHDRAW_ESTIMATE_MAX_SUCCESS:
      return {
        ...state,
        fetchingMaxAmount: false,
        maxAmount:action.payload.amount
      };

    case WITHDRAW_ESTIMATE_MAX_ERROR:
      return {
        ...state,
        fetchingMaxAmount: false,
        error: action.payload.data.message
      };
      case WITHDRAW_ESTIMATE_MAX_RESET:
        return {
          ...state,
          maxAmount: null,
          error: null
        };
    case CHECK_AUTOSIGN_STATUS_REQUEST:
      return {
        ...state,
        autosignStatus: null,
        autosignError: null
      }
    case CHECK_AUTOSIGN_STATUS_SUCCESS:
      return {
        ...state,
        autosignStatus: action.payload.data,
        autosignError: null,
      }
    case CHECK_AUTOSIGN_STATUS_ERROR:
      return {
        ...state,
        autosignStatus: null,
        autosignError: action.payload.data.message
      }
    case UPLOAD_NEW_AUTOSIGN_SUCCESS:
      return {
        ...state,
        autosignStep: action.payload.data?.emailSent ? 1 : 2
      }
    case RESET_AUTOSIGN_STEP:
      return {
        ...state,
        autosignStep: 0
      }
    default:
      return state;
  }
}
