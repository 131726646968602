import React  from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SwapBack from '../../SwapNew/SwapBack';
import { ReactComponent as AmountFromIcon } from '../../../img/swap-svg/swap-amount-from.svg';
import { ReactComponent as AmountToIcon } from '../../../img/swap-svg/swap-amount-to.svg';
import { ReactComponent as FloatingIcon } from '../../../img/swap-svg/swap-floating.svg';
import { ReactComponent as FixedIcon } from '../../../img/swap-svg/swap-fixed.svg';
import getSwapPartnerIcon from '../../../utils/swapPartnerIcons';
import network from '../../../utils/transactionScans';
import './style.scss';
import { identity } from '../../../utils/getIdentity';
import { getHashFromLink } from '../../../utils/getHashFromLink';

const SwapDetailsMobile = ({ transactionDetailsData, setTransactionDetailsData, currencies, networkFilter }) => {
  const {t} = useTranslation();

  return (
    <div className="swap-details-mobile">
      <SwapBack action={() => setTransactionDetailsData(null)} />
      <div className="swap-details-mobile__card">
        <div className="swap-details-mobile__title">{t('swap.transactions')}</div>
        <div className="swap-details-mobile__id">{transactionDetailsData.exchangeId}</div>
        <div className="swap-details-mobile__date">
          {moment(transactionDetailsData.createdAt).format('MM.D.YYYY, HH:mm')}
        </div>
        <div
          className={`swap-details-mobile__status swap-details-mobile__status_${transactionDetailsData.systemStatus.toLowerCase()}`}
        >
          <span>{transactionDetailsData.systemStatus}</span>
        </div>
        <div className="swap-details-mobile__amount">
          <div className="swap-details-mobile__amount-title">{t('swap.swapAmount')}</div>
          <div className="swap-details-mobile__amount-to">
            <AmountToIcon />
            {transactionDetailsData.to.amount.value}
            <span className="swap-details-mobile__amount-name">{transactionDetailsData.to.currency.name}</span>
          </div>
          <div className="swap-details-mobile__amount-from">
            <AmountFromIcon />
            {transactionDetailsData.from.amount.value}
            <span className="swap-details-mobile__amount-name">{transactionDetailsData.from.currency.name}</span>
          </div>
        </div>
        <div className="swap-details-mobile__partner">
        {t('swap.partner')}:
          <span className="swap-details-mobile__partner-value">
            <img src={getSwapPartnerIcon(transactionDetailsData.partner)} alt={transactionDetailsData.partner} />{' '}
            {transactionDetailsData.partner}
          </span>
        </div>
        <div className="swap-details-mobile__type">
        {t('swap.type')}:
          {transactionDetailsData.fixed ? (
            <span className="swap-details-mobile__type-value">
              <FixedIcon /> {t('swap.fixed')}
            </span>
          ) : (
            <span className="swap-details-mobile__type-value">
              <FloatingIcon /> {t('swap.floating')}
            </span>
          )}
        </div>

        <div className="swap-history-details__card">
          <div className="swap-history-details__card-title">{t('swap.offerItem.from')}</div>
          <div className="swap-history-details__wallet">
            <span className="swap-history-details__wallet-title">{t('swap.offerItem.wallet')}:</span>{' '}
            <span className="swap-history-details__wallet-value">{transactionDetailsData.from.address}</span>
          </div>
          <div className="swap-history-details__paymentId">
            <span className="swap-history-details__paymentId-title">PaymentId:</span>{' '}
            <span className="swap-history-details__paymentId-value">{transactionDetailsData.from.paymentId}</span>
          </div>
          <div className="swap-history-details__amount">
            <span className="swap-history-details__amount-title">{t('swap.offerItem.amount')}:</span>{' '}
            <span className="swap-history-details__amount-value">
              {transactionDetailsData.from.amount.value} {transactionDetailsData.from.currency.name} ⸺ $
              {transactionDetailsData.from.amount.usd}
            </span>
          </div>
          {transactionDetailsData.from.explorerUrl && transactionDetailsData.from.hash !== null ? (
            <div className="swap-history-details__hash">
            <span className="swap-history-details__hash-title">Hash:</span>{' '}
            <span className="swap-history-details__hash-value">
              <a
                target="_blank"
                rel='noopener noreferrer'
                href={`${transactionDetailsData.from.explorerUrl}`}
                className={`link link-${identity}`}
              >
                {getHashFromLink(transactionDetailsData.from.explorerUrl)}
              </a>
            </span>
          </div> )
          : null }
        </div>

        <div className="swap-history-details__card">
          <div className="swap-history-details__card-title">{t('swap.offerItem.to')}</div>
          <div className="swap-history-details__wallet">
            <span className="swap-history-details__wallet-title">{t('swap.offerItem.wallet')}:</span>{' '}
            <span className="swap-history-details__wallet-value">{transactionDetailsData.to.address}</span>
          </div>
          <div className="swap-history-details__paymentId">
            <span className="swap-history-details__paymentId-title">PaymentId:</span>{' '}
            <span className="swap-history-details__paymentId-value">{transactionDetailsData.to.paymentId}</span>
          </div>
          <div className="swap-history-details__amount">
            <span className="swap-history-details__amount-title">{t('swap.offerItem.amount')}:</span>{' '}
            <span className="swap-history-details__amount-value">
              {transactionDetailsData.to.amount.value} {transactionDetailsData.to.currency.name} ⸺ $
              {transactionDetailsData.to.amount.usd}
            </span>
          </div>
          {transactionDetailsData.to.explorerUrl && transactionDetailsData.to.hash !== null ? (
          <div className="swap-history-details__hash">
            <span className="swap-history-details__hash-title">Hash:</span>{' '}
            <span className="swap-history-details__hash-value">
              <a
                target="_blank"
                rel='noopener noreferrer'
                href={`${transactionDetailsData.to.explorerUrl}`}
                className={`link link-${identity}`}
              >
                {getHashFromLink(transactionDetailsData.to.explorerUrl)}
              </a>
            </span>
          </div>)
        : null
        }
        </div>
      </div>
    </div>
  );
};

export default SwapDetailsMobile;
