import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getChargeData, createChargeIdReset, getChargeDataReset } from '../redux/actions/checkoutPayment';
import qs from 'qs';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getContrastTextColor } from '../utils/getTextColorByBg';
import CheckoutStepsProgress from '../components/CheckoutPayment/CheckoutStepsProgress';
import ClientDataForm from '../components/CheckoutPayment/ClientDataForm';
import Initial from '../components/CheckoutPayment/Initial';
import Success from '../components/CheckoutPayment/Success';
import Expired from '../components/CheckoutPayment/Expired';
import Failed from '../components/CheckoutPayment/Failed';
import Pending from '../components/CheckoutPayment/Pending';
import Button from '../components/Button';
import InfoIcon from '../img/default-svg/bill.svg';
import NotFound from '../components/CheckoutPayment/NotFound';
import LanguageSelector from '../components/LanguageSelector';
import Partial from '../components/CheckoutPayment/Partial/Partial';
import './CheckoutPayment.scss';
import { AppConfig } from '../config';
import { mainColor } from '../utils/getMainColor';
import { Helmet } from 'react-helmet';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CheckoutPaymentPage = ({ getChargeData, getChargeState, createChargeIdReset, getChargeDataReset }) => {
  const [initialStep, setInitialStep] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [expired, setExpired] = useState(false);
  const [timer, setTimer] = useState(null);
  const [isCustomerDataRequired, setIsCustomerDataRequired] = useState(true);
  const [showCustomerDataForm, setShowCustomerDataForm] = useState(true);
  const [donationAmount, setDonationAmount] = useState('');
  const [showAmountRequired, setShowAmountRequired] = useState(false);
  const [params, setParams] = useState('');
  const timerRef = useRef(null);
  const { chargeId } = useParams();
  const query = useQuery();
  let history = useHistory();
  const { t } = useTranslation();
  const textColor = getContrastTextColor(getChargeState?.data?.checkout?.backgroundColor || '#f1f3f6');
  const accentColor = getChargeState.data?.checkout?.accentColor || mainColor;

  const [partialPayment, setPartialPayment] = useState(false);
  const [showCheckoutPaymentBtn, setShowCheckoutPaymentBtn] = useState(true);

  const [checkoutDeleted, setCheckoutDeleted] = useState(false);

  const checkWalletStatus = () => {
    if (getChargeState?.data?.replenish && !selectedCurrency) {
      const {
        wallets,
        replenish: { walletAddress, currency, currencyType, nodeType },
      } = getChargeState?.data;

      const walletToPay = wallets.find(wallet => {
        if (walletAddress) return wallet.address === walletAddress;
        return (
          wallet.currency.name === currency &&
          wallet.currency.nodeType === nodeType &&
          wallet.currency.currencyType === currencyType
        );
      });
      setSelectedCurrency(walletToPay);
    }
  };

  useEffect(() => {
    if (!getChargeState || !getChargeState.data) return;

    setIsCustomerDataRequired(
      getChargeState.data?.checkout?.collectName || getChargeState?.data?.checkout?.collectEmail
    );

    setShowCustomerDataForm(
      (getChargeState.data?.checkout?.collectName && !getChargeState.data.customerName) ||
        (getChargeState.data?.checkout?.collectEmail && !getChargeState.data.customerEmail)
    );

    getChargeState?.data?.systemStatus === 'Partial' && checkWalletStatus();
    setCheckoutDeleted(() => (!getChargeState?.data?.checkout ? true : false));
  }, [getChargeState]);

  const buttonBackHandler = () => {
    setInitialStep(true);
    setSelectedCurrency(null);
    setDonationAmount('');
    setShowAmountRequired(false);
  };

  const buttonRestartHandler = () => {
    const { identifier } = getChargeState.data.checkout;
    createChargeIdReset();
    getChargeDataReset();
    history.push(`/checkout/${identifier}${params ? `?${params}` : ''}`);
  };

  const handleBackToCustomerInfo = () => {
    setShowCustomerDataForm(true);
  };

  const stepHandler = wallet => {
    setSelectedCurrency(wallet);
    setInitialStep(false);
  };

  const partialStepHandler = () => {
    if (getChargeState?.data?.systemStatus === 'Partial') {
      setPartialPayment(true);
      setInitialStep(false);
      return;
    }

    setPartialPayment(false);
  };

  useEffect(() => {
    if (getChargeState?.data?.systemStatus === 'Done') {
      setPartialPayment(false);
      setInitialStep(false);
      return;
    }
  }, [getChargeState]);

  useEffect(() => {
    return partialPayment ? setShowCheckoutPaymentBtn(false) : setShowCheckoutPaymentBtn(true);
  }, [partialPayment]);

  const COMPONENT_LIST = {
    ClientDataForm: {
      component: <ClientDataForm getChargeState={getChargeState} getChargeData={getChargeData} />,
    },
    New: {
      description: initialStep
        ? t('checkouts.checkoutForm.description')
        : `${t('checkouts.checkoutForm.makeAPayment')} ${
            selectedCurrency && selectedCurrency.currency && selectedCurrency.currency.name
          } ${t('checkouts.checkoutForm.addressBelow')}`,
      component: (
        <Initial
          selectedCurrency={selectedCurrency}
          initialStep={initialStep}
          stepHandler={stepHandler}
          wallets={getChargeState.data && getChargeState.data.wallets}
          getChargeState={getChargeState}
          donationAmount={donationAmount}
          setDonationAmount={setDonationAmount}
          showAmountRequired={showAmountRequired}
          setShowAmountRequired={setShowAmountRequired}
          partialPayment={partialPayment}
          accentColor={accentColor}
        />
      ),
      buttonAction: !initialStep
        ? buttonBackHandler
        : !isCustomerDataRequired || showCustomerDataForm
        ? null
        : handleBackToCustomerInfo,
      buttonText: t('backButton'),
      buttonType: 'secondary',
    },
    Pending: {
      component: <Pending />,
    },
    Done: {
      component: (
        <Success
          replenish={getChargeState?.data && getChargeState?.data?.replenish}
          fiatCurrency={getChargeState.data?.checkout && (getChargeState.data?.checkout?.fiatCurrency || 'USD')}
          wallets={getChargeState.data && getChargeState.data.wallets}
          typeNetwork={getChargeState.data && getChargeState.data.typeNetwork}
          accentColor={accentColor}
        />
      ),
      buttonAction: buttonRestartHandler,
      buttonText: t('checkouts.success.button'),
      buttonType: 'secondary',
    },
    Partial: {
      component: (
        <Partial
          replenish={getChargeState?.data && getChargeState?.data?.replenish}
          fiatCurrency={getChargeState.data?.checkout && (getChargeState.data?.checkout?.fiatCurrency || 'USD')}
          wallets={getChargeState.data && getChargeState.data.wallets}
          typeNetwork={getChargeState.data && getChargeState.data.typeNetwork}
          selectedCurrency={selectedCurrency}
          systemStatus={getChargeState?.data?.systemStatus}
          accentColor={accentColor}
        />
      ),
      buttonAction: partialStepHandler,
      buttonText: t('checkouts.partial.button'),
      buttonType: 'secondary',
    },
    Failed: {
      component: <Failed />,
      buttonAction: buttonRestartHandler,
      buttonText: t('retryButton'),
      buttonType: 'primary',
    },
    Expired: {
      component: (
        <Expired
          hasPrice={getChargeState.data?.checout && getChargeState.data.checkout?.price}
          buttonRestartHandler={buttonRestartHandler}
        />
      ),
      buttonAction: buttonRestartHandler,
      buttonText: t('retryButton'),
      buttonType: 'primary',
    },
  };

  const millisToMinutesAndSeconds = millis => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  };

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
      timerRef.current = null;
    };
  }, []);

  useEffect(() => {
    let obj = {};
    for (var pair of query.entries()) {
      obj[pair[0]] = pair[1];
    }
    setParams(qs.stringify(obj));
  }, []);

  useEffect(() => {
    if (chargeId) {
      createSocket(chargeId);
      getChargeData(
        chargeId,
        query.get('amount') && query.get('currency') && { amount: query.get('amount'), currency: query.get('currency') }
      );
    }
  }, [chargeId]);

  useEffect(() => {
    if (getChargeState.data) {
      const { expiredDate } = getChargeState.data;
      const timeLeft = new Date(expiredDate) - new Date();
      if (timeLeft > 0) {
        setTimer(timeLeft);
        if (!timerRef.current) {
          timerRef.current = setInterval(() => {
            setTimer(timer => (timer ? timer - 1000 : timeLeft));
          }, 1000);
        }
      } else {
        setExpired(true);
      }
    }
  }, [getChargeState.data]);

  useEffect(() => {
    if (timerRef.current && timer <= 0) {
      clearInterval(timerRef.current);
      setExpired(true);
      setTimer(null);
      timerRef.current = null;
    }
  }, [timer]);

  useEffect(() => {
    if (getChargeState.data) {
      if (getChargeState.data.systemStatus === 'Done' || getChargeState.data.systemStatus === 'Failed') {
        clearInterval(timerRef.current);
        timerRef.current = null;
        setExpired(false);
        setTimer(null);
      }
    }
  }, [getChargeState]);

  const createSocket = savedId => {
    const io = window.io;
    const socket = io(process.env.REACT_APP_API_URL, {
      allowEIO3: true,
      withCredentials: true,
      // extraHeaders: {
      //   'Access-Control-Allow-Credentials': true
      // }
    });
    socket.on('connect', function () {
      socket.emit('sign-in-charge', savedId);
    });
    socket.on('sign-in-charge', function (data) {});
    socket.on('confirmedTransaction', function (data) {
      getChargeData(
        savedId,
        query.get('amount') && query.get('currency') && { amount: query.get('amount'), currency: query.get('currency') }
      );
      setPartialPayment(false);
      setShowCheckoutPaymentBtn(true);
      socket.emit('disconnect');
    });
    socket.on('incomingTransaction', function (data) {
      getChargeData(
        savedId,
        query.get('amount') && query.get('currency') && { amount: query.get('amount'), currency: query.get('currency') }
      );
      setPartialPayment(false);
      setShowCheckoutPaymentBtn(true);
    });
    socket.on('disconnect', function () {});
  };

  const data = getChargeState.data;
  const fetching = getChargeState.fetching;
  const renderPartialPaymentStep = !partialPayment
    ? COMPONENT_LIST[data?.systemStatus]?.component
    : COMPONENT_LIST['New']?.component;

  return (
    <>
      {AppConfig.identity === 'cpay' && (
        <Helmet>
          <link rel="icon" href="/cpay-icons/favicon.ico" type="image/x-icon" />
        </Helmet>
      )}

      {AppConfig.identity === 'nfg' && (
        <Helmet>
        <link rel="icon" href="/nfgpay-icons/favicon.ico" type="image/x-icon" />
      </Helmet>
      )}
      <style>
        {`
          .checkout-payment__button {
            color: ${accentColor} !important;
            border-color: ${accentColor} !important;
          }
          .checkout-payment__button:hover:not(:disabled) {
            background: ${accentColor} !important;
            color: ${getContrastTextColor(accentColor || mainColor)} !important;
          }
          .checkout-progress__step_active {
            background: ${accentColor} !important;
          }
        `}
      </style>
      {getChargeState.error || checkoutDeleted ? (
        <NotFound title={t('checkouts.checkoutForm.charge')} />
      ) : (
        <div
          className="checkout-payment"
          style={{ background: getChargeState?.data?.checkout?.backgroundColor || 'transparent' }}
        >
          {getChargeState?.data?.checkout?.logoImage && (
            <div className="checkout-payment__custom-logo">
              <img
                src={process.env.REACT_APP_API_URL + getChargeState?.data?.checkout?.logoImage}
                alt="Checkout logo"
              />
            </div>
          )}
          <div className="checkout-payment__header">
            <div className="checkout-payment__title" style={{ color: textColor }}>
              {t('checkouts.checkoutForm.title')}
            </div>
            <LanguageSelector />

            {!expired && (
              <div className="checkout-payment__description" style={{ color: textColor }}>
                {!initialStep && data && COMPONENT_LIST[data.systemStatus].description
                  ? COMPONENT_LIST[data.systemStatus].description
                  : t('checkouts.checkoutForm.description')}
              </div>
            )}
            {!expired && getChargeState.data && showCustomerDataForm ? (
              <>
                {isCustomerDataRequired && <CheckoutStepsProgress step={1} />}
                <div className="checkout-payment__info">
                  <img src={InfoIcon} className="checkout-payment__info-logo" alt="info" />
                  <div className="checkout-payment__info-wrapper">
                    <div className="checkout-payment__info-title">
                      {(getChargeState.data?.checkout && getChargeState.data?.checkout?.organizationName) ||
                        (getChargeState.data?.checkout && getChargeState.data?.checkout?.productName)}
                    </div>
                    <div className="checkout-payment__info-description">
                      {getChargeState.data?.checkout ? getChargeState.data?.checkout?.description : ''}
                    </div>
                  </div>
                </div>
                {COMPONENT_LIST.ClientDataForm.component}
              </>
            ) : getChargeState.data?.checkout && getChargeState.data?.checkout?.image ? (
              <>
                {isCustomerDataRequired && <CheckoutStepsProgress step={2} />}
                <div className="checkout-payment__info-container">
                  {!fetching && (
                    <div className="checkout-payment__info-big">
                      <div className="checkout-payment__info-big-wrapper">
                        <div className="checkout-payment__info-big-title">
                          {(getChargeState.data?.checkout && getChargeState.data?.checkout?.organizationName) ||
                            (getChargeState.data?.checkout && getChargeState.data?.checkout?.productName)}
                        </div>
                        <div className="checkout-payment__info-big-description">
                          {getChargeState.data ? getChargeState.data.checkout.description : ''}
                        </div>
                      </div>
                      <div className="checkout-payment__info-big-logo">
                        <img
                          src={`${process.env.REACT_APP_API_URL}${getChargeState.data?.checkout?.image}`}
                          alt="info"
                        />
                      </div>
                    </div>
                  )}
                  <div className={getChargeState.fetching ? 'checkout-payment-loading' : 'checkout-payment__container'}>
                    {expired
                      ? COMPONENT_LIST['Expired'].component
                      : fetching
                      ? `${t('checkouts.checkoutForm.description')}...`
                      : data
                      ? renderPartialPaymentStep
                      : null}
                  </div>
                </div>
              </>
            ) : (
              <>
                {!fetching && (
                  <>
                    {isCustomerDataRequired && <CheckoutStepsProgress step={2} />}
                    <div className="checkout-payment__info">
                      <img src={InfoIcon} className="checkout-payment__info-logo" alt="info" />
                      <div className="checkout-payment__info-wrapper">
                        <div className="checkout-payment__info-title">
                          {(getChargeState.data && getChargeState.data.checkout.organizationName) ||
                            (getChargeState.data && getChargeState.data.checkout.productName)}
                        </div>
                        <div className="checkout-payment__info-description">
                          {getChargeState.data ? getChargeState.data.checkout.description : ''}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className={getChargeState.fetching ? 'checkout-payment-loading' : 'checkout-payment__container'}>
                  {expired ? (
                    COMPONENT_LIST['Expired'].component
                  ) : fetching ? (
                    <p>{`${t('checkouts.checkoutForm.description')}...`}</p>
                  ) : data ? (
                    renderPartialPaymentStep
                  ) : null}
                  {fetching && <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />} />}
                </div>
              </>
            )}
            {timer && !expired && (
              <div className="checkout-payment__timer" style={{ color: textColor }}>{`${t(
                'checkouts.checkoutForm.timer'
              )} ${millisToMinutesAndSeconds(timer)}`}</div>
            )}
            {expired ? (
              <Button
                type={COMPONENT_LIST['Expired'].buttonType}
                className="checkout-payment__button"
                onClick={COMPONENT_LIST['Expired'].buttonAction}
              >
                {COMPONENT_LIST['Expired'].buttonText}
              </Button>
            ) : (
              data &&
              !fetching &&
              COMPONENT_LIST[data.systemStatus].buttonAction &&
              showCheckoutPaymentBtn && (
                <Button
                  type={COMPONENT_LIST[data.systemStatus].buttonType}
                  className="checkout-payment__button"
                  onClick={COMPONENT_LIST[data.systemStatus].buttonAction}
                >
                  {COMPONENT_LIST[data.systemStatus].buttonText}
                </Button>
              )
            )}
          </div>
          <div className="checkout-payment__footer" style={{ color: textColor }}>
            <a href="http://www.nfg-crypto.io/" target="_blank" rel="noopener noreferrer">
              {t('checkouts.checkoutForm.paymentsProcessed')} <span>{AppConfig.title}</span>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  getChargeState: state.checkoutPayment.getCharge,
});

const mapDispatchToProps = {
  getChargeData,
  createChargeIdReset,
  getChargeDataReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentPage);
