import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import { Row, Col, Modal, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { deactivateEmailTwoFactor } from '../../redux/actions/twoFactor';
import { changeEmailTwoFaStatus } from '../../redux/actions/user';
import '../../layout/Modal.scss';
import './style.scss'
import { identity } from '../../utils/getIdentity';


const EmailTwoFaDisableModal = ({ emailTwoFaFetching, deactivateEmailTwoFactor, changeEmailTwoFaStatus }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [emailCode, setEmailCode] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (isVisible) {
      deactivateEmailTwoFactor();
    }
  }, [isVisible]);

  const handleSubmit = async () => {
    const response = await deactivateEmailTwoFactor(emailCode);
    if (response) {
      setIsVisible(false);
      changeEmailTwoFaStatus(false);
    }
  };

  const handleCancel = e => {
    setIsVisible(false);
  };

  return (
    <React.Fragment>
      <Button type="primary" className="form__button" onClick={() => setIsVisible(true)}>
        {t('profile.disableTwoFa')}
      </Button>
      <Modal
        width={534}
        title={t('profile.disableTwoFa')}
        visible={isVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={null}
        className="modal"
      >
        <div className='disable-email-twofa'>
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          className="modal-form"
          onFinish={handleSubmit}
        >
          <Form.Item
            label={t("auth.keyFromEmail")}
            name="authCode"
            className="modal-form__label"
            rules={[{ required: true, message: t('validation.required') }]}
          >
            <Input
              placeholder={t("auth.keyFromEmail")}
              className="modal-form__input"
              onChange={e => setEmailCode(e.target.value)}
            />
          </Form.Item>
          <Row className="modal__buttons-wrapper">
            <Col span="12">
              <Button
                loading={emailTwoFaFetching}
                disabled={emailTwoFaFetching}
                type="primary"
                className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
              >
                {t('disable')}
              </Button>
            </Col>
            <Col span="12">
              <Button
                type="primary"
                className="modal__button modal__button-cancel"
                onClick={e => {
                  e.preventDefault();
                  handleCancel();
                }}
                disabled={emailTwoFaFetching}
              >
                {t('cancel')}
              </Button>
            </Col>
          </Row>
        </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  deactivateEmailTwoFactor: deactivateEmailTwoFactor(dispatch),
  changeEmailTwoFaStatus: changeEmailTwoFaStatus(dispatch),
});

export default connect(null, mapDispatchToProps)(EmailTwoFaDisableModal);
