import { AppConfig } from "../config";
import { mainColor } from "./getMainColor";

const colorsObj = {
  BTC: {
    color: '#F99400',
    bgColor: '#FBF7F3',
  },
  ETH: {
    color: '#2988EF',
    bgColor: '#EBF6FF',
  },
  USDT: {
    color: '#1BA27A',
    bgColor: 'rgba(113, 251, 135, 0.22)',
  },
  BNB: {
    color: '#F99400',
    bgColor: '#FBF7F3',
  },
  BUSD: {
    color: '#F99400',
    bgColor: '#FBF7F3',
  },
  TRX: {
    color: '#FC0D24',
    bgColor: 'rgba(252, 13, 36, 0.07)',
  },
  LOTT: {
    color: '#390C7F',
    bgColor: 'rgba(57, 12, 127, 0.1)',
  },
  default: {
    color: mainColor,
    bgColor: '#EBF6FF',
  },
};

export const tokenColors = currency => {
  return colorsObj[currency] || colorsObj.default;
};
