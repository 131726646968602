import React from 'react';
import { Modal } from "antd";
import '../../layout/Modal.scss';
import './style.scss';
import { useTranslation } from "react-i18next";
import SetPasswordForm from './SetPasswordForm';


function SignPasswordModal({ isOpen, onCancel }) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('signPassword.setUp')}
      visible={isOpen}
      onCancel={onCancel}
      width={369}
      footer={null}
      className="modal"
    >
      <div className="sign-password-modal">
        <SetPasswordForm onCancel={onCancel} />
      </div>
    </Modal>
  )
}

export default SignPasswordModal;