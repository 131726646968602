import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Tabs } from 'antd';

import { useTranslation } from 'react-i18next';
import { notificationsErrorShow } from '../../../redux/actions/notifications';

import Replenish from './Replenish';
import PrivateKey from './PrivateKey';

import '../../../layout/Modal.scss';
import './WalletSettingsModal.scss';
import './styles.scss';
import MnemonicPhrase from './MnemonicPhrase';

const { TabPane } = Tabs;

const BTC_CODE = '5edf2767c9ca4d5a342bf8ac';

const WalletSettingsModal = ({
  isAppTwoFa,
  isEmailTwoFa,
  isPhoneTwoFa,
  openSettingsModal,
  setOpenSettingsModal,
  selectedWallet,
  wallet,
  merchantId,
  setSelectedWallet,
  getWalletPrivateKey,
  getWalletMnemonicPhrase,
  openNotification,
  resetTwoFaState,
}) => {
  const [activeTab, setActiveTab] = useState('replenish');
  const [walletKey, setWalletKey] = useState('');
  const [appTwoFa, setAppTwoFa] = useState('');
  const [emailTwoFa, setEmailTwoFa] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    setSelectedWallet(selectedWallet);
  });

  console.log('selectedWallet', selectedWallet);

  console.log('wallet', wallet);

  console.log('isAppTwoFa', isAppTwoFa, isEmailTwoFa, isPhoneTwoFa);

  const { t } = useTranslation();

  const handleOk = () => {
    setOpenSettingsModal(false);
    setSelectedWallet(null);
  };

  const handleCancel = e => {
    setSelectedWallet(null);
    setOpenSettingsModal(false);
    setAppTwoFa('');
    setEmailTwoFa('');
    setVerifyCode('');
    setWalletKey('');
    resetTwoFaState();
  };

  const onChange = key => {
    if (key === 'private') {
      //  const res = getWalletPrivateKey(merchantId, selectedWallet._id);
      const response = getWalletPrivateKey(merchantId, selectedWallet._id).then(res => res);
      console.log('res', response);
    }

    if (key === 'mnemonic') {
      //  const res = getWalletPrivateKey(merchantId, selectedWallet._id);
      const response = getWalletMnemonicPhrase(merchantId, selectedWallet._id).then(res => res);
      console.log('res', response);
    }

    setAppTwoFa('');
    setEmailTwoFa('');
    setVerifyCode('');
    setWalletKey('');
    resetTwoFaState();

    setActiveTab(key);
  };

  return (
    <Modal
      title={t('merchants.merchantWallet')}
      visible={openSettingsModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={400}
      className="modal modal-settings"
      afterClose={() => {
        setAppTwoFa('');
        setEmailTwoFa('');
        setVerifyCode('');
        setWalletKey('');
        form.resetFields();
        resetTwoFaState();
      }}
    >
      <div className="modal modal-settings__body">
        <Tabs defaultActiveKey={activeTab} centered onChange={onChange} tabBarGutter={8}>
          <TabPane tab={t('merchants.replenish')} key="replenish" />
          <TabPane tab={t('privateKey')} key="private" />
          <TabPane tab={t('mnemonic')} key="mnemonic" />
        </Tabs>

        {activeTab === 'replenish' && (
          <Replenish
            selectedWallet={selectedWallet}
            wallet={wallet}
            setSelectedWallet={setSelectedWallet}
            setOpenSettingsModal={setOpenSettingsModal}
          />
        )}

        {activeTab === 'private' && (
          <PrivateKey
            merchantId={merchantId}
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
            openNotification={openNotification}
            getWalletPrivateKey={getWalletPrivateKey}
            setOpenSettingsModal={setOpenSettingsModal}
            walletKey={walletKey}
            appTwoFa={appTwoFa}
            emailTwoFa={emailTwoFa}
            verifyCode={verifyCode}
            setWalletKey={setWalletKey}
            setAppTwoFa={setAppTwoFa}
            setEmailTwoFa={setEmailTwoFa}
            setVerifyCode={setVerifyCode}
          />
        )}

        {activeTab === 'mnemonic' && (
          <MnemonicPhrase
            merchantId={merchantId}
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
            openNotification={openNotification}
            setOpenSettingsModal={setOpenSettingsModal}
            getWalletMnemonicPhrase={getWalletMnemonicPhrase}
            walletKey={walletKey}
            appTwoFa={appTwoFa}
            emailTwoFa={emailTwoFa}
            verifyCode={verifyCode}
            setWalletKey={setWalletKey}
            setAppTwoFa={setAppTwoFa}
            setEmailTwoFa={setEmailTwoFa}
            setVerifyCode={setVerifyCode}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isAppTwoFa: state.merchantWallets.isAppTwoFa,
  isEmailTwoFa: state.merchantWallets.isEmailTwoFa,
  isPhoneTwoFa: state.merchantWallets.isPhoneTwoFa,
  isFetching: state.merchantWallets.modalFetching,
});

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletSettingsModal);
