import React from 'react';
import { useTranslation } from 'react-i18next';
import Wallet from '../Wallet';
import WithdrawModeToggler from '../WithdrawModeToggler';
import emptyCpayImage from '../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../img/nfgpay-svg/empty-nfg.svg';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { identity } from '../../../utils/getIdentity';
import './style.scss';

const emptyImage = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage,
};

const WalletsList = ({
  withdrawWallets,
  walletsFetching,
  selectedWallet,
  setSelectedWallet,
  setFrom,
  setCurrency,
  multisendMode,
  setMultisendMode,
  walletListRef,
  currencies,
  currency,
}) => {
  const { t } = useTranslation();

  const getWalletInfo = (id, address) => {
    setCurrency(id);
    setFrom(address);
  };

  const walletsWithBalances = withdrawWallets?.filter(wallet => wallet?.tokens[0]?.balance?.usd !== 0);

  return (
    <div className="withdraw-wallets" ref={walletListRef}>
      <WithdrawModeToggler multisendMode={multisendMode} setMultisendMode={setMultisendMode} />
      {!walletsFetching ? (
        <div className="withdraw-wallets__list">
          {withdrawWallets.length ? (
            withdrawWallets.map(wallet =>
              currency &&
              currencies.find(
                curr => curr._id === currency && curr.currencyType === 'token' && wallet?.tokens?.length === 0
              ) ? null : (
                <Wallet
                  key={wallet._id}
                  wallet={wallet}
                  selectedWallet={selectedWallet}
                  setSelectedWallet={setSelectedWallet}
                  getWalletInfo={getWalletInfo}
                />
              )
            )
          ) : (
            <div className="withdraw-wallets__empty">
              <span className="transactions-list__empty-title">{t('wallets.emptyList')}</span>
              <img src={emptyImage[identity]} className="transactions-list__empty-image" alt="empty" />
            </div>
          )}
          {/* {selectedWallet && (
            <button
              type="button"
              className={`withdraw-wallets__select withdraw-wallets__select-${identity}`}
              disabled={!selectedWallet}
              onClick={() => {
                setCurrency(selectedWallet._id);
                setFrom(selectedWallet.address);
              }}
            >
              {t('send')}
            </button>
          )} */}
        </div>
      ) : (
        <div className="withdraw-wallets__loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default WalletsList;
