import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Popover, Tooltip } from 'antd';
import TokenIcon from '../../../../components/TokenIcon';
import { tokenColors } from '../../../../utils/tokenIcons';
import formatCurrency from '../../../../utils/currencyFormatter';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Copy } from '../../../../img/default-svg/copy.svg';
import { tokenTypeEnd } from '../../../../utils/tokenNodeTypes';

const Wallet = ({ wallet, selectedWallet, setSelectedWallet, setFrom }) => {
  const [isTokensOpened, setIsTokensOpened] = useState(true);
  const [hasTokens, setHasTokens] = useState(false);
  const [walletTokens, setWalletTokens] = useState([]);
  const { t } = useTranslation();
  const isSelected =
    selectedWallet && !selectedWallet.hasOwnProperty('holdBalance') && wallet._id === selectedWallet._id;

  const [showAddressPopover, setShowAddressPopover] = useState(false);
  const addressPopoverTimer = useRef(null);
  const [popoverVisibility, setPopoverVisibility] = useState({});
  const [showTooltip, setShowTooltip] = useState({});

  const addressCopyHandler = value => {
    navigator.clipboard.writeText(value);
    setShowAddressPopover(true);
    setShowTooltip({});

    addressPopoverTimer.current = setTimeout(() => {
      setShowAddressPopover(false);
      setPopoverVisibility({ ...popoverVisibility, [wallet._id]: false }); // Hide the Popover after 2 seconds
    }, 2000);
    addressPopoverTimer.current = setTimeout(() => setShowAddressPopover(false), 1000);
  };

  useEffect(() => {
    const filtered = wallet.tokens.filter(token => token.balance.usd > 0);
    setHasTokens(!!filtered.length);
    setWalletTokens(filtered);
  }, [wallet]);

  useEffect(() => {
    if (
      !isTokensOpened &&
      selectedWallet &&
      selectedWallet.hasOwnProperty('holdBalance') &&
      selectedWallet.address === wallet.address
    ) {
      setSelectedWallet(null);
    }
  }, [isTokensOpened]);

  useEffect(() => {
    return () => {
      clearTimeout(addressPopoverTimer);
      addressPopoverTimer.current = null;
    };
  });

  return (
    <div
      className={`fee-wallets-list__wallet ${hasTokens ? 'fee-wallets-list__wallet_hasTokens' : ''}`}
      onMouseEnter={e => (e.currentTarget.style.borderColor = tokenColors(wallet.currency).color)}
      onMouseLeave={e =>
        (e.currentTarget.style.borderColor = (!hasTokens || !isTokensOpened) && isSelected ? 'transparent' : '#E0E6ED')
      }
      style={
        (!hasTokens || !isTokensOpened) && isSelected
          ? {
              background: tokenColors(wallet.currency).bgColor,
              borderColor: 'transparent',
            }
          : {
              background: 'transparent',
              borderColor: '#E0E6ED',
            }
      }
    >
      {hasTokens ? (
        <div
          className={`fee-wallets-list__dropdown-icon ${
            isTokensOpened ? 'fee-wallets-list__dropdown-icon_opened' : ''
          }`}
          onClick={() => setIsTokensOpened(!isTokensOpened)}
        >
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 6.12598L8 10.126L12 6.12598"
              stroke={tokenColors(wallet.currency).color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ) : (
        ''
      )}
      <Row
        align="middle"
        onClick={() => {
          setSelectedWallet(wallet);
        }}
        style={
          hasTokens && isTokensOpened && isSelected
            ? {
                background: tokenColors(wallet.currency).bgColor,
                borderColor: 'transparent',
              }
            : {
                background: 'transparent',
                borderColor: '#E0E6ED',
              }
        }
      >
        <Col span={1}>
          <TokenIcon tokenName={wallet.currency} className="fee-wallets-list__logo" />
        </Col>
        <Col span={14}>
          <div className="fee-wallets-list__address" style={{ color: tokenColors(wallet.currency).color }}>
            {wallet.address}
            {selectedWallet &&
            selectedWallet._id === wallet._id &&
            wallet.currency === selectedWallet.currency ? null : (
              <Tooltip
                title={t('clickToCopy')}
                placement="top"
                visible={showTooltip[wallet._id] || false}
                onVisibleChange={visible => setShowTooltip({ ...showTooltip, [wallet._id]: visible })}
              >
                <Popover
                  title=""
                  content={t('copied')}
                  trigger="click"
                  visible={popoverVisibility[wallet._id] || false}
                  onVisibleChange={visible => setPopoverVisibility({ ...popoverVisibility, [wallet._id]: visible })}
                  // className="detailed-checkout__popover"
                >
                  <div
                    className="fee-wallets-list__address-copy"
                    onClick={e => {
                      e.stopPropagation();
                      addressCopyHandler(wallet.address);
                    }}
                  >
                    <Copy />
                  </div>
                </Popover>
              </Tooltip>
            )}
            {selectedWallet && selectedWallet._id === wallet._id && wallet.currency === selectedWallet.currency && (
              <button
                className="fee-wallets-list__address-button"
                type="button"
                onClick={() => setFrom(selectedWallet.address)}
              >
                {t('send')}
              </button>
            )}
          </div>
        </Col>
        <Col span={9}>
          <div className="fee-wallets-list__balance">
            <div className="fee-wallets-list__balance-token">
              {wallet.balance.value} {wallet.currency}{' '}
              {tokenTypeEnd(wallet.currencyType, wallet.nodeType, wallet.currency)}
            </div>
            <div className="fee-wallets-list__balance-usd">{formatCurrency(wallet.balance.usd)}</div>
          </div>
        </Col>
      </Row>
      {isTokensOpened && (
        <div className="fee-wallets-list__tokens-dropdown">
          {walletTokens.map(token => {
            const isTokenSelected =
              selectedWallet && selectedWallet.currencyId === token.currencyId && wallet._id === selectedWallet._id;
            return (
              <Row
                key={token.currencyId}
                align="middle"
                className="fee-wallets-list__token"
                onClick={() => {
                  setSelectedWallet({ ...token, _id: wallet._id, address: wallet.address });
                }}
                style={{ background: isTokenSelected ? tokenColors(token.currency).bgColor : 'transparent' }}
              >
                <Col span={1}>
                  <TokenIcon tokenName={token.currency} className="fee-wallets-list__logo" />
                </Col>
                <Col span={14}>
                  <div className="fee-wallets-list__address" style={{ color: tokenColors(token.currency).color }}>
                    {wallet.address}
                    {selectedWallet &&
                    selectedWallet._id === wallet._id &&
                    selectedWallet.currency === token.currency ? null : (
                      <Tooltip
                        title={t('clickToCopy')}
                        visible={showTooltip[token.currency] || false}
                        onVisibleChange={visible => setShowTooltip({ ...showTooltip, [token.currency]: visible })}
                        placement="top"
                      >
                        <Popover
                          title=""
                          content={t('copied')}
                          trigger="click"
                          visible={popoverVisibility[token.currency] || false}
                          onVisibleChange={visible =>
                            setPopoverVisibility({ ...popoverVisibility, [token.currency]: visible })
                          }
                          // className="detailed-checkout__popover"
                        >
                          <div
                            className="fee-wallets-list__address-copy"
                            onClick={e => {
                              e.stopPropagation();
                              addressCopyHandler(wallet.address);
                            }}
                          >
                            <Copy />
                          </div>
                        </Popover>
                      </Tooltip>
                    )}
                    {selectedWallet &&
                      selectedWallet._id === wallet._id &&
                      selectedWallet.currency === token.currency && (
                        <button
                          className="fee-wallets-list__address-button"
                          type="button"
                          onClick={() => setFrom(selectedWallet.address)}
                        >
                          {t('send')}
                        </button>
                      )}
                  </div>
                </Col>
                <Col span={9}>
                  <div className="fee-wallets-list__balance">
                    <div className="fee-wallets-list__balance-token">
                      {token.balance.value} {token.currency}
                    </div>
                    <div className="fee-wallets-list__balance-usd">{formatCurrency(token.balance.usd)}</div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Wallet;
