import { cryptoApi } from '../../service/cryptopay-api';
import { notificationsErrorShow } from './notifications';

export const MULTISEND_ESTIMATE_RESET = 'MULTISEND_ESTIMATE_RESET';

export const multisendEstimateReset = () => ({
  type: MULTISEND_ESTIMATE_RESET,
});

export const MULTISEND_ESTIMATE_SYSTEM_FEE_GET = 'MULTISEND_ESTIMATE_SYSTEM_FEE_GET';
export const MULTISEND_ESTIMATE_SYSTEM_FEE_SET = 'MULTISEND_ESTIMATE_SYSTEM_FEE_SET';
export const MULTISEND_ESTIMATE_SYSTEM_FEE_ERROR = 'MULTISEND_ESTIMATE_SYSTEM_FEE_ERROR';

const multisendEstimateSystemFeeGet = () => ({
  type: MULTISEND_ESTIMATE_SYSTEM_FEE_GET,
});

// TODO
// const multisendEstimateSystemFeeSet = () => ({
//   type: MULTISEND_ESTIMATE_SYSTEM_FEE_SET,
// });
const multisendEstimateSystemFeeError = () => ({
  type: MULTISEND_ESTIMATE_SYSTEM_FEE_ERROR,
});

export const getMultisendSystemFee = dispatch => async (merchantId, walletId, body) => {
  dispatch(multisendEstimateSystemFeeGet());
  try {
    const response = await cryptoApi.getMultisendSystemFee(merchantId, walletId, body);

    if (response && response.status === 'success') {
      return response.data;
    }
    // dispatch(multisendEstimateSystemFeeSet(response));
  } catch (error) {
    dispatch(multisendEstimateSystemFeeError());
    dispatch(notificationsErrorShow(error.data));
    throw error;
  }
};

export const MULTISEND_APPROVE_GET = 'MULTISEND_APPROVE_GET';
export const MULTISEND_APPROVE_SET = 'MULTISEND_APPROVE_SET';
export const MULTISEND_APPROVE_ERROR = 'MULTISEND_APPROVE_ERROR';

const multisendApproveGet = () => ({
  type: MULTISEND_APPROVE_GET,
});

// TODO
// const multisendApproveSet = () => ({
//   type: MULTISEND_APPROVE_SET,
// });

const multisendApproveError = () => ({
  type: MULTISEND_APPROVE_ERROR,
});

export const multisendApprove = dispatch => async (merchantId, walletId, estimationId) => {
  dispatch(multisendApproveGet());
  try {
    const response = await cryptoApi.multisendApprove(merchantId, walletId, estimationId);

    if (response && response.status === 'success') {
      return response.data;
    }
    // dispatch(multisendApproveSet(response));
  } catch (error) {
    dispatch(multisendApproveError());
    dispatch(notificationsErrorShow(error.data));
    throw error;
  }
};

export const MULTISEND_ESTIMATE_MINER_FEE_GET = 'MULTISEND_ESTIMATE_MINER_FEE_GET';
export const MULTISEND_ESTIMATE_MINER_FEE_SET = 'MULTISEND_ESTIMATE_MINER_FEE_SET';
export const MULTISEND_ESTIMATE_MINER_FEE_ERROR = 'MULTISEND_ESTIMATE_MINER_FEE_ERROR';

const multisendEstimateMinerFeeGet = () => ({
  type: MULTISEND_ESTIMATE_MINER_FEE_GET,
});

const multisendEstimateMinerFeeSet = payload => ({
  type: MULTISEND_ESTIMATE_MINER_FEE_SET,
  payload,
});

const multisendEstimateMinerFeeError = () => ({
  type: MULTISEND_ESTIMATE_MINER_FEE_ERROR,
});

export const getMultisendMinerFee = dispatch => async (merchantId, walletId, estimationId) => {
  dispatch(multisendEstimateMinerFeeGet());
  try {
    const response = await cryptoApi.getMultisendMinerFee(merchantId, walletId, estimationId);
    dispatch(multisendEstimateMinerFeeSet(response.data));
  } catch (error) {
    dispatch(multisendEstimateMinerFeeError());
    dispatch(notificationsErrorShow(error.data));
    throw error;
  }
};

export const MULTISEND_WITHDRAW_GET = 'MULTISEND_WITHDRAW_GET';
export const MULTISEND_WITHDRAW_SET = 'MULTISEND_WITHDRAW_SET';
export const MULTISEND_WITHDRAW_ERROR = 'MULTISEND_WITHDRAW_ERROR';

const multisendWithdrawGet = () => ({
  type: MULTISEND_WITHDRAW_GET,
});

const multisendWithdrawSet = payload => ({
  type: MULTISEND_WITHDRAW_SET,
  payload,
});

const multisendWithdrawError = () => ({
  type: MULTISEND_WITHDRAW_ERROR,
});

export const multisendWithdraw = dispatch => async (merchantId, walletId, estimationId, appTwoFa, emailTwoFa, verifyCode) => {
  dispatch(multisendWithdrawGet());
  try {
    const response = await cryptoApi.multisendWithdraw(merchantId, walletId, estimationId, appTwoFa, emailTwoFa, verifyCode);
    dispatch(multisendWithdrawSet(response.data.ids));
    return response;
  } catch (error) {
    dispatch(multisendWithdrawError());
    dispatch(notificationsErrorShow(error.data));
    throw error;
  }
};
