import React from 'react';
import Wallet from '../Wallet';
import emptyCpayImage from '../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../img/nfgpay-svg/empty-nfg.svg';
import { Spin } from 'antd';
import { identity } from '../../../utils/getIdentity';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';

const emptyImage = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage
}

const WalletsList = ({ withdrawWallets, walletsFetching }) => {
  return (
    <div className="fee-wallets-list">
      {!walletsFetching ? (
        <div className="fee-wallets-list__list">
          {withdrawWallets.length ? (
            withdrawWallets.map(wallet => <Wallet key={wallet._id} wallet={wallet} />)
          ) : (
            <div className="fee-wallets-list__empty">
              <span className="transactions-list__empty-title">All available wallets will show up here.</span>
              <img src={emptyImage[identity]} className="transactions-list__empty-image" alt="empty" />
            </div>
          )}
        </div>
      ) : (
        <div className="fee-wallets-list__loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default WalletsList;
