import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import LoginInfo from '../../components/LoginInfo/LoginInfo';
import { AppConfig } from '../../config';
import logoCpay from '../../img/footer/footerLogo.svg';
import logoNfg from '../../img/nfgpay-svg/nfg_logo_black.svg'
import useWindowSize from '../../utils/resizeHook';
import LanguageSelector from '../../components/LanguageSelector';
import './style.scss';

const logos = {
  cpay: logoCpay,
  nfg: logoNfg
}

const AuthPagesWrapper = ({ childComponent: ChildComponent, withWelcomeBlock }) => {
  const [isHideWelcomeBlock, setIsHideWelcomeBlock] = useState(!withWelcomeBlock);
  const [accountType, setAccountType] = useState(null);
  const { isSm } = useWindowSize();

  const toggleWelcomeBlock = status => setIsHideWelcomeBlock(status);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="auth-wrapper">
        <div className="auth-wrapper__block">
          <div className="auth-wrapper__form-block">
            <div className="auth-wrapper__header">
              <a href={AppConfig.identity !== 'nfg' ? 'https://cpay.world/' : null} target='_blank' rel="noopener noreferrer">
              <img className="auth-wrapper__logo-top" src={logos[AppConfig.identity]} alt="Logo" />
              </a>
              <LanguageSelector />
            </div>
            <ChildComponent
              toggleWelcomeBlock={toggleWelcomeBlock}
              setAccountType={setAccountType}
              accountType={accountType}
            />
          </div>
          {!isSm && withWelcomeBlock && !isHideWelcomeBlock && (
            <div className="auth-wrapper__welcome-block">
              <LoginInfo accountType={accountType} />
              <div>
                <a href={AppConfig.identity !== 'nfg' ? 'https://cpay.world/' : null} target='_blank' rel="noopener noreferrer">
                   <img className="auth-wrapper__logo-bottom" src={logos[AppConfig.identity]} alt="Logo" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AuthPagesWrapper;
