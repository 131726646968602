import React from 'react';
import { useTranslation } from 'react-i18next';
import NotFound from '../components/CheckoutPayment/NotFound';
import { AppConfig } from '../config';
import { Helmet } from 'react-helmet';

const DeletedCharge = () => {
    const {t} = useTranslation();
  return (
    <>
      {AppConfig.identity === 'cpay' && (
    <Helmet>
      <link rel="icon" href="/cpay-icons/favicon.ico" type="image/x-icon" />
    </Helmet>
     )}

    {AppConfig.identity === 'nfg' && (
      <Helmet>
      <link rel="icon" href="/nfgpay-icons/favicon.ico" type="image/x-icon" />
    </Helmet>
    )}
    <NotFound title={t('checkouts.checkoutForm.charge')}/>
    </>

  )
}

export default DeletedCharge