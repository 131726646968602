import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload } from 'antd';
import plusIconCpay from '../../../../../img/cpay-svg/plus.svg';
import plusIconNfg from '../../../../../img/nfgpay-svg/plus-nfg.svg';
import crossIcon from '../../../../../img/default-svg/close.svg';
import './style.scss';
import { identity } from '../../../../../utils/getIdentity';

const plusIcon = {
  cpay: plusIconCpay,
  nfg: plusIconNfg
}

const UploadComponent = ({ handleChange, image }) => {
  const [currentImg, setCurrentImg] = useState(image);
  const [imgUrl, setImgUrl] = useState(null);
  const [imgError, setImgError] = useState(false);
  const [imgErrorText, setImgErrorText] = useState('.');
  const [imgData, setImgData] = useState(null);
  const { t } = useTranslation();

  const getBase64 = img => {
    let file = img;
    let reader = new FileReader();
    reader.onloadend = function () {
      setImgUrl(reader.result);
      setImgData(img);
    };
    reader.readAsDataURL(file);
  };

  const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
    if (!isJpgOrPng) {
      setImgError(true);
      setImgErrorText(t('checkouts.imageFormat'));
      return false;
    }
    const isLt1M = file.size / 1024 / 1024 <= 1;
    if (!isLt1M) {
      setImgError(true);
      setImgErrorText(t('checkouts.imageSize'));
      return false;
    }
    getBase64(file);
  };

  const clearImage = () => {
    if (currentImg) {
      setImgData('delete');
      setCurrentImg(null);
    } else {
      setImgUrl(null);
      setImgData(null);
    }
  };
  useEffect(() => {
    handleChange(imgData);
  }, [imgData]);
  return (
    <>
      {imgUrl || currentImg ? (
        <div className="uploaded-img-container">
          <img src={imgUrl || `${process.env.REACT_APP_API_URL}${currentImg}`} alt="avatar" className="uploaded-img" />
          <div className="uploaded-img-container__delete" onClick={clearImage}>
            <img src={crossIcon} alt="" />
          </div>
        </div>
      ) : (
        <>
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            maxCount={1}
            beforeUpload={beforeUpload}
            onClick={() => setImgError(null)}
          >
            <div>
              <div className={`upload-button upload-button-${identity}`}>
                <img src={plusIcon[identity]} alt="" />
                <p>{t('checkouts.addPhoto')}</p>
              </div>
            </div>
          </Upload>
          <p className={imgError ? 'upload-button-error-visible' : 'upload-button-error'}>{imgErrorText}</p>
        </>
      )}
    </>
  );
};

export default UploadComponent;
