import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Row, Col, Checkbox } from 'antd';
import Button from '../Button';
import '../../layout/Modal.scss';
import { useTranslation } from 'react-i18next';
import { createMerchantWallet } from '../../redux/actions/merchantWallets';
import { notificationsErrorShow } from '../../redux/actions/notifications';
import CurrencyDropdown from '../CurrencyDropdown';
import { identity } from '../../utils/getIdentity';

const WalletCreateNew = ({
  isFetching,
  merchantId,
  networkFilter,
  typeWallet,
  notificationsErrorShow,
  createMerchantWallet,
  handleCancel,
  page,
  walletsFilters,
  isFromMerchantsWallets,
  merchantData,
  currencies,
}) => {
  const [walletCurrency, setWalletCurrency] = useState([]);
  const [walletSetMain, setWalletSetMain] = useState(false);
  const [activeMerchantCurrencies, setActiveMerchantCurrencies] = useState([]);
  const [isOpenedDropdownCurrency, setIsOpenedDropdownCurrency] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (merchantId && merchantData) {
      const activeCurrencies =
        typeWallet === 'merchant' ? currencies.filter(currency => currency.currencyType !== 'token') : currencies;
      const activeCurrenciesIds = activeCurrencies.map(({ _id }) => _id);
      const merchantCurrencies = activeCurrenciesIds.filter(curr => merchantData.currencies.indexOf(curr) !== -1);
      setActiveMerchantCurrencies(merchantCurrencies);
    }
  }, [merchantId, merchantData, typeWallet]);

  const handleSubmit = () => {
    if (!walletCurrency) {
      notificationsErrorShow({ message: 'Currency is required' });
      return;
    }

    if (isButtonDisabled) return;
    setIsButtonDisabled(true);
    createMerchantWallet(
      merchantId,
      typeWallet,
      {
        setMain: walletSetMain,
        typeWallet: typeWallet,
        currenciesIds: walletCurrency,
      },
      page,
      walletsFilters,
      networkFilter,
      isFromMerchantsWallets
    ).then(() => {
      handleCancel();
      // setIsButtonDisabled(false);
    });
  };

  return (
    <div
      onClick={() => {
        isOpenedDropdownCurrency && !visible ? setIsOpenedDropdownCurrency(false) : console.log('nothing to see');
      }}
    >
      <CurrencyDropdown
        setFunc={setWalletCurrency}
        multiple={true}
        allCurrencies={false}
        noTokens={typeWallet === 'merchant'}
        merchantId={merchantId}
        individualTokens={typeWallet !== 'merchant' ? true : false}
        isFiltersAvailable={true}
        activeMerchantCurrencies={activeMerchantCurrencies}
        isOpenedDropdownCurrency={isOpenedDropdownCurrency}
        setIsOpenedDropdownCurrency={setIsOpenedDropdownCurrency}
        visible={visible}
        setVisible={setVisible}
      />

      {typeWallet === 'merchant' && (
        <div className="modal__set-default" onClick={() => setIsOpenedDropdownCurrency(false)}>
          <Checkbox onChange={e => setWalletSetMain(e.target.checked)}>{t('wallets.setAsDefault')}</Checkbox>
        </div>
      )}

      <Row className="modal__buttons-wrapper" onClick={() => setIsOpenedDropdownCurrency(false)}>
        <Col>
          <Button
            type="primary"
            className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
            onClick={handleSubmit}
            loading={isFetching}
          >
            {t('create')}
          </Button>
        </Col>
        <Col>
          <Button type="primary" className="modal__button modal__button-cancel" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  page: state.merchantWallets.page,
  isFetching: state.merchantWallets.modalFetching,
  walletsFilters: formValueSelector(props.filtersFormName)(state, 'search', 'order', 'limit', 'currencyIds'),
  merchantData: state.merchantData.data,
  currencies: state.currencies.data,
});

const mapDispatchToProps = dispatch => ({
  createMerchantWallet: createMerchantWallet(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletCreateNew);
