import React, { useRef, useState } from 'react';
import { Modal, Form, Input } from "antd";
import '../../../layout/Modal.scss';
import './style.scss';
import '../../ResendEmail2FA/style.scss';
import { addMinutes } from "../../../utils/addMinutes";
import Button from "../../Button";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import addAutosignIcon from '../../../img/cpay-svg/add.svg';
import uploadedFiles from '../../../img/cpay-svg/uploadedFile.svg';
import crossIcon from '../../../img/default-svg/close-blue.svg';
import '../../../layout/Modal.scss';
import { Timer } from "../../Timer/Timer";
import { identity } from "../../../utils/getIdentity";
import { resetAutosignStep } from "../../../redux/actions/withdraw";

function UploadAutosignModal({ isOpen, onCancel, uploadAutosign, step }) {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [disableResend, setDisableResend] = useState(true);
  const [password, setPassword] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);
  const [emailTwoFa, setEmailTwoFa] = useState('');
  const [twoFactorToken, setTwoFactorToken] = useState('');

  console.log(step)

  const closeModal = () => {
    onCancel();
    setPassword('');
    setEmailTwoFa('');
    setTwoFactorToken('');
    form.setFieldsValue({ password: '', emailTwoFa: '', twoFactorToken: '' });
    setUploadedFile(null);
    dispatch(resetAutosignStep());
  }

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
      setDisableResend(false);
    }, 60000);

    return () => clearTimeout(timeout);
  };

  const resendVerifyCode = async () => {
    try {
      await uploadAutosign({ password: password, sign: fileBase64 })
      setDisableResend(true);
      handleDisableResend();
    } catch (error) {
      console.log('error', error);
    }
  };

  const validUntil = addMinutes(1);

  const handleUploadImage = () => {
    fileInputRef.current.click()
  }

  const handleFile = async (e) => {
    const file = e.target.files[0]

    if (!file) return;

    setUploadedFile(file);

    const base64 = await convertToBase64(file);
    const trimmedBase64 = base64.replace(/^data:application\/pdf;base64,/, '');
    setFileBase64(trimmedBase64);
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const removeFile = () => {
    setUploadedFile(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const cropFileName = (name) => {
    if (name.length > 20) {
      return `${name.slice(0, 20)}...`;
    }

    return name;
  }

  return (
    <Modal
      title={t('withdraw.addAutosign')}
      visible={isOpen}
      onCancel={onCancel}
      width={369}
      footer={null}
      className="modal"
    >
      <div className="autosign-modal">
        <Form
          form={form}
          className="form"
        >
          {step === 0 && (
            <div>
              <div className="autosign-modal__add">
                <div  className="autosign-modal__inner" onClick={handleUploadImage}>
                  <img src={!uploadedFile ? addAutosignIcon : uploadedFiles} alt="add" />
                  <span className="autosign-modal__text">{!uploadedFile ? t('withdraw.addAutosign') : cropFileName(uploadedFile.name)}</span>
                </div>
                {uploadedFile && <img src={crossIcon} alt="delete" className="autosign-modal__add__close" onClick={removeFile} />}
              </div>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFile}
              />
              <Form.Item
                label={t('password')}
                name="password"
                className="form__item"
                value={password}
              >
                <Input.Password
                  className="form__input autosign-modal__password"
                  placeholder={t('password')}
                  onChange={e => setPassword(e.target.value)}
                />
              </Form.Item>
            </div>
          )}

          {step === 1 && (
            <div>
              <Form.Item
                label={t('auth.keyFromEmail')}
                name="emailTwoFaCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.keyFromEmail')}
                  className="modal-form__input"
                  maxLength="6"
                  value={emailTwoFa}
                  onChange={e => setEmailTwoFa(e.target.value)}
                />
              </Form.Item>
              <div className="resend-email-twoFa">
                <span>{t('doNotReveiveCode')}</span>
                {disableResend ? (
                  <span className="resend-email-twoFa__timer">
                    {' '}
                    Receive in <Timer validUntil={validUntil} onExpire={() => setDisableResend(false)} />
                  </span>
                ) : (
                  <span
                    className={`resend-email-twoFa__button resend-email-twoFa__button-${identity}`}
                    onClick={resendVerifyCode}
                  >
                    {' '}
                    {t('resend')}
                  </span>
                )}
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <Form.Item
                label={t('auth.keyFromGA')}
                name="twoFactorToken"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.twoFaRequired') }]}
              >
                <Input
                  className="modal-form__input"
                  placeholder={t('auth.keyFromGA')}
                  maxLength="6"
                  value={twoFactorToken}
                  onChange={e => setTwoFactorToken(e.target.value)}
                />
              </Form.Item>
            </div>
          )}

          <div className="autosign-modal__buttons">
            <Button type="secondary" className="form__button autosign-modal__btn" onClick={closeModal}>
              {t('cancel')}
            </Button>
            <Button onClick={() => uploadAutosign({ password: password, sign: fileBase64, emailOtp: emailTwoFa, twoFactorToken: twoFactorToken })} disabled={
              step === 0 ? (!uploadedFile || !password) : step === 1 ? emailTwoFa.length < 6 : step === 2 ? twoFactorToken.length < 2 : null
            } type="primary" className="form__button autosign-modal__btn">
              {step === 0 ? t('withdraw.add') : t('withdraw.verify')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default UploadAutosignModal;