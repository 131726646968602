import React, { useState } from 'react';
import Button from "../Button";
import { Form, Input } from "antd";
import '../../layout/Modal.scss';
import { useTranslation } from "react-i18next";

function SetPasswordForm({ onCancel }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    password: '',
    confirmPassword: ''
  })

  return (
    <div className="sign-password">
      <Form
        form={form}
        className="form"
      >
        <Form.Item
          label={t('signPassword.password')}
          name="password"
          className="form__item"
        >
          <Input.Password
            className="form__input sign-password__input"
            placeholder={t('signPassword.password')}
            onChange={e => setValues({ ...values, password: e.target.value })}
          />
        </Form.Item>

        <Form.Item
          label={t('signPassword.confirmPassword')}
          name="password"
          className="form__item"
        >
          <Input.Password
            className="form__input sign-password__input"
            placeholder={t('signPassword.confirmPassword')}
            onChange={e => setValues({ ...values, confirmPassword: e.target.value })}
          />
        </Form.Item>

        <div className="sign-password__buttons">
          <Button type="secondary" className="form__button sign-password__btn" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="primary" className="form__button sign-password__btn">
            {t('signPassword.create')}
          </Button>
        </div>
      </Form>
    </div>
    )
}

export default SetPasswordForm;